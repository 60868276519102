import { Button } from '@/components/common/Button';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormLabelRequire,
    FormMessage
} from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Textarea } from '@/components/common/Textarea';
import { ACTIVE, INACTIVE } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { Company } from '@/types/company';
import { toastify } from '@/utils/toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { flowResult } from 'mobx';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface UiCreateCompanyProps {
    onFetch: Function
}
interface UiChangeStatusCompanyProps {
    idCompany: number,
    value: boolean,
    onFetch: Function
}
interface UiDeleteCompanyProps {
    idCompany: number,
    onFetch: Function
}
interface UiUpdateCompanyProps {
    id: number,
    onFetch: Function
}

const UiCreateCompany: React.FC<UiCreateCompanyProps> = ({ onFetch }) => {
    const {
        companyStore: { getListCompany, createCompany },
        modalStore: { hideModal }
    } = useStore();

    const validateSchema = yup.object().shape({
        company_name: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        post_code: yup.string().matches(/^[0-9]+$/, 'form_error_validate.number_only').required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        address: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        shared_folder_url_id: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        channel_access_token: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        line_profile_link: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        liff_id: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        admin_email: yup.string().email('form_error_validate.email').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'form_error_validate.email').required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        admin_name: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        contract_plan: yup.string().max(500, 'form_error_validate.max_500')
    });

    type FormData = yup.InferType<typeof validateSchema>;

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            company_name: '',
            post_code: '',
            address: '',
            shared_folder_url_id: '',
            channel_access_token: '',
            line_profile_link: '',
            liff_id: '',
            contract_plan: '',
            admin_email: '',
            admin_name: ''
        }
    });

    const onSubmit = async (data: FormData) => {
        const createRes: any = await flowResult(createCompany(data));
        if (createRes === true) {
            onFetch();
            toastify('success', t('messages.add_success'));
            onClose();
        }
    };

    const onClose = () => {
        form.reset();
        hideModal();
    };

    return (

        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <p className='text-black mt-3 text-[18px] font-bold text-left'>基本情報</p>
                <div className='flex'>
                    <div className='w-1/2'>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='company_name'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            事業者名
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='post_code'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            郵便番号
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    type='number'
                                                    pattern='[0-9]*'
                                                    onKeyPress={(e) => {
                                                        console.log(form.getValues('post_code'));
                                                        if (!/[0-9]/.test(e.key) || form.getValues('post_code').length >= 255) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onPaste={(e) => {
                                                        const pasteValue = e.clipboardData.getData('text');
                                                        if (!/^[0-9]+$/.test(pasteValue) || form.getValues('post_code').length >= 255 || (form.getValues('post_code').length + pasteValue.length > 255)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='address'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            住所
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className='w-1/2 ml-6'>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='shared_folder_url_id'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            共有Googleドライブフォルダー
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='line_profile_link'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            LINEのプロフィールリンク
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='liff_id'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            LINEのLIFF ID
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className=''>
                    <FormField
                        control={form.control}
                        name='channel_access_token'
                        render={({ field }) => (
                            <FormItem className=''>
                                <FormLabelRequire>
                                    LINEチャンネルアクセストークン
                                </FormLabelRequire>
                                <FormControl className='w-full'>
                                    <Textarea maxLength={255} rows={2} className='bg-bgInput min-h-[40px]' {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className=''>
                    <FormField
                        control={form.control}
                        name='contract_plan'
                        render={({ field }) => (
                            <FormItem className=''>
                                <FormLabel>
                                    契約プラン
                                </FormLabel>
                                <FormControl className='w-full'>
                                    <Textarea maxLength={500} rows={2} className='bg-bgInput min-h-[40px]' {...field} />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                </div>
                <p className='text-black mt-6 text-[18px] font-bold  text-left'>管理者情報</p>
                <div className='flex'>
                    <div className='w-1/2'>
                        <FormField
                            control={form.control}
                            name='admin_name'
                            render={({ field }) => (
                                <FormItem className=''>
                                    <FormLabelRequire>
                                        管理者名
                                    </FormLabelRequire>
                                    <div className='w-full'>
                                        <FormControl>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </div>

                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='w-1/2 ml-6'>
                        <FormField
                            control={form.control}
                            name='admin_email'
                            render={({ field }) => (
                                <FormItem className=''>
                                    <FormLabelRequire>
                                        管理者のメールアドレス
                                    </FormLabelRequire>
                                    <div className='w-full'>
                                        <FormControl>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </div>

                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-row text-center mt-8 w-full justify-end'>
                    <Button type='button' onClick={onClose} variant='outline' className='min-w-32 text-red400 '>
                        キャンセル
                    </Button>
                    <Button type='submit' disabled={form.formState.isSubmitting} className='min-w-32  ml-[20px] bg-main text-white '>
                        追加
                    </Button>
                </div>
            </form>
        </Form>

    );
};

const UiChangeStatusCompany: React.FC<UiChangeStatusCompanyProps> = ({ idCompany, value, onFetch }) => {
    const {
        companyStore: { changeStatus, getListCompany },
        modalStore: { hideModal }
    } = useStore();

    const onChange = async () => {
        const res = await flowResult(changeStatus(idCompany.toString(), { status: value ? ACTIVE : INACTIVE }));
        hideModal();
        if (res) {
            // await flowResult(getListCompany());
            toastify('success', t('messages.update_success'));
            onFetch && onFetch();
        }
    };

    return (
        <div className='h-30 w-full'>
            <p className='mt-6 text-[14px] text-black'>{value ? t('messages.confirm_active') : t('messages.confirm_inactive') }</p>
            <div className='flex flex-row text-center mt-8 w-full justify-end'>
                <Button onClick={() => hideModal()} className='min-w-32 bg-gray-300 text-black' variant='outline'>
                    いいえ
                </Button>
                <Button onClick={() => onChange()} className='min-w-32  ml-[20px] bg-red400 text-white' variant='outline'>
                    はい
                </Button>
            </div>
        </div>
    );
};

const UiDeleteCompany: React.FC<UiDeleteCompanyProps> = ({ idCompany, onFetch }) => {
    const {
        companyStore: { getListCompany, deleteCompany, defaultPaging },
        modalStore: { hideModal }
    } = useStore();

    const onChange = async () => {
        const res = await flowResult(deleteCompany(idCompany.toString()));
        hideModal();
        if (res) {
            if (onFetch) {
                onFetch();
            } else {
                await flowResult(getListCompany(undefined, defaultPaging));
            }
            toastify('success', t('messages.delete_success'));
        }
    };

    return (
        <div className='h-30 w-full'>
            <p className='mt-6 text-[14px] text-black'>削除してもよろしいですか？</p>
            <div className='flex flex-row text-center mt-8 w-full justify-end'>
                <Button onClick={() => hideModal()} className='min-w-32 bg-gray-300 text-black' variant='outline'>
                    いいえ
                </Button>
                <Button onClick={() => onChange()} className='min-w-32  ml-[20px] bg-red400 text-white' variant='outline'>
                    はい
                </Button>
            </div>
        </div>
    );
};

const UiUpdateCompany: React.FC<UiUpdateCompanyProps> = ({ id, onFetch }) => {
    const {
        companyStore: { getListCompany, getDetailCompany, updateCompany },
        modalStore: { hideModal }
    } = useStore();
    const [detail, setDetail] = useState<Company | undefined>(undefined);

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        if (detail) {
            form.setValue('company_name', detail.name);
            form.setValue('post_code', detail.post_code);
            form.setValue('address', detail.address);
            form.setValue('shared_folder_url_id', detail.shared_folder_url_id);
            form.setValue('channel_access_token', detail.channel_access_token);
            form.setValue('line_profile_link', detail.line_profile_link);
            form.setValue('contract_plan', detail.contract_plan || '');
            form.setValue('admin_email', detail.admin_email || '');
            form.setValue('admin_name', detail.admin_name || '');
            form.setValue('liff_id', detail.liff_id || '');
        }
    }, [detail]);

    const fetch = async () => {
        const currentData = await flowResult(getDetailCompany(id.toString()));
        setDetail(currentData);
    };

    const validateSchema = yup.object().shape({
        company_name: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        post_code: yup.string().matches(/^[0-9]+$/, 'form_error_validate.number_only').required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        address: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        shared_folder_url_id: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        channel_access_token: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        line_profile_link: yup.string().optional(),
        liff_id: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        admin_email: yup.string().email('form_error_validate.email').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'form_error_validate.email').required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        admin_name: yup.string().trim().required('form_error_validate.required').max(255, 'form_error_validate.max_255'),
        contract_plan: yup.string().max(500, 'form_error_validate.max_500')
    });

    type FormData = yup.InferType<typeof validateSchema>;

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: detail
    });

    const onSubmit = async (data: FormData) => {
        const res = await flowResult(updateCompany(id.toString(), data));
        if (res) {
            onClose();
            toastify('success', t('messages.update_success'));
            onFetch();
        }
    };

    const onClose = () => {
        form.reset();
        hideModal();
    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <p className='text-black text-left mt-3 text-[16px] font-bold'>基本情報</p>
                <div className='flex'>
                    <div className='w-1/2'>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='company_name'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            事業者名
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='post_code'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            郵便番号
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    type='number'
                                                    pattern='[0-9]*'
                                                    onKeyPress={(e) => {
                                                        if (!/[0-9]/.test(e.key) || form.getValues('post_code').length >= 255) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    onPaste={(e) => {
                                                        const pasteValue = e.clipboardData.getData('text');
                                                        if (!/^[0-9]+$/.test(pasteValue) || form.getValues('post_code').length >= 255 || (form.getValues('post_code').length + pasteValue.length > 255)) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='address'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            住所
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className='w-1/2 ml-6'>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='shared_folder_url_id'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            共有Googleドライブフォルダー
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='line_profile_link'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            LINEのプロフィールリンク
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className=''>
                            <FormField
                                control={form.control}
                                name='liff_id'
                                render={({ field }) => (
                                    <FormItem className=''>
                                        <FormLabelRequire>
                                            LINEのLIFF ID
                                        </FormLabelRequire>
                                        <div className='w-full'>
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </div>

                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className=''>
                    <FormField
                        control={form.control}
                        name='channel_access_token'
                        render={({ field }) => (
                            <FormItem className=''>
                                <FormLabelRequire>
                                    LINEチャンネルアクセストークン
                                </FormLabelRequire>
                                <FormControl className='w-full'>
                                    <Textarea maxLength={255} rows={2} className='bg-bgInput min-h-[40px]' {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </div>
                <div className='text-left'>
                    <FormField
                        control={form.control}
                        name='contract_plan'
                        render={({ field }) => (
                            <FormItem className=''>
                                <FormLabel>
                                    契約プラン
                                </FormLabel>
                                <FormControl className='w-full'>
                                    <Textarea maxLength={500} rows={2} className='bg-bgInput min-h-[40px]' {...field} />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                </div>
                <p className='text-black mt-6 text-[18px] font-bold  text-left'>管理者情報</p>
                <div className='flex'>
                    <div className='w-1/2'>
                        <FormField
                            control={form.control}
                            name='admin_name'
                            render={({ field }) => (
                                <FormItem className=''>
                                    <FormLabelRequire>
                                        管理者名
                                    </FormLabelRequire>
                                    <div className='w-full'>
                                        <FormControl>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </div>

                                </FormItem>
                            )}
                        />
                    </div>
                    <div className='w-1/2 ml-6'>
                        <FormField
                            control={form.control}
                            name='admin_email'
                            render={({ field }) => (
                                <FormItem className=''>
                                    <FormLabelRequire>
                                        管理者のメールアドレス
                                    </FormLabelRequire>
                                    <div className='w-full'>
                                        <FormControl>
                                            <Input
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </div>

                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <div className='flex flex-row text-center mt-8 w-full justify-end'>
                    <Button type='button' onClick={onClose} variant='outline' className='min-w-32 text-red400 '>
                        キャンセル
                    </Button>
                    <Button type='submit' disabled={form.formState.isSubmitting} className='min-w-32 ml-[20px] bg-red400 text-white '>
                        保存
                    </Button>
                </div>
            </form>
        </Form>
    );
};

export { UiCreateCompany, UiChangeStatusCompany, UiDeleteCompany, UiUpdateCompany };
