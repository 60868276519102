import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: classNames.ArgumentArray) {
    return twMerge(classNames(inputs));
}

export function getAvatarFallbackName(fullname?: string) {
    if (!fullname) return '';
    const names = fullname.trim().split(' ');

    if (names.length === 1) {
    // Trường hợp chỉ có một từ
        return `${names[0].charAt(0).toUpperCase()}${names[0]
            .charAt(1)
            .toUpperCase()}`;
    } else if (names.length > 1) {
    // Lấy ký tự đầu tiên của tên và họ
        const firstInitial = names[0].charAt(0).toUpperCase();
        const lastInitial = names[names.length - 1].charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
    }

    return '';
}

export function sortAtoZ(array: any[]) {
    const finalData = array.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });
    return finalData;
}

export function sortZtoA(array: any[]) {
    const finalData = array.sort((a, b) => {
        if (a.name < b.name) return 1;
        if (a.name > b.name) return -1;
        return 0;
    });
    return finalData;
}

export function generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export const bytesToMB = (bytes: number) =>
    Number.parseFloat((bytes / (1000 * 1024)).toFixed(2));

export let globalVar = {
    avatar: '',
    shared_folder_url_id: '',
    role_id: 0,
    status: 0
};
