import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormLabelRequire, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { getStorage } from '@/utils/browsers';
import { yupResolver } from '@hookform/resolvers/yup';
import { da } from 'date-fns/locale';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { gapi } from 'gapi-script';
import { getIdFolderDriveFromURL } from '@/utils/file';
import { toastify } from '@/utils/toastify';
import GoogleAPI from '@/services/drive';
import { t } from 'i18next';
import { Profile } from '@/types/auth';

interface UserFormProps {
    id?: number,
    currentCusId?: string,
    onFetch: Function
}

export default observer(function UserForm({ id, currentCusId, onFetch }: UserFormProps) {
    // store
    const { modalStore: { hideModal },
        authStore: { profile, setProfile },
        userStore: { createUser, getUserDetail, updateUserInfor, getListUsers },
        apiStore: { setLoading },
        modalStore: { showErrorModal },
        companyStore: { getDetailCompany }
    } = useStore();
    // validate schema
    const validateSchema = yup.object().shape({
        customer_id: yup.string().trim().required('form_error_validate.required').matches(/^[^_]*$/, '顧客IDにアンダースコア（_）は使用できません。'),
        line_id: yup.string(),
        folder_url_id: yup.string()
    });
    const googleAPIService = GoogleAPI.getInstance();

    type FormData = yup.InferType<typeof validateSchema>;

    // state

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: {
            customer_id: '',
            line_id: '',
            folder_url_id: ''
        }
    });

    // lifecycle
    useEffect(() => {
        const initGoogleAPI = async () => {
            await googleAPIService.initClient();
            // getRootFolder();
        };
        initGoogleAPI();
        if (id) {
            getUserInfor(id);
        }
    }, []);

    const getUserInfor = async (id: number) => {
        const res = await flowResult(getUserDetail({ id }));
        if (res) {
            form.reset({
                customer_id: res.customer_id,
                line_id: res.line_id
            });
        }
    };

    const onSubmit = async (data: FormData) => {
        const detail = await flowResult(getDetailCompany(`${profile?.company_id}`, true));
        if (!detail) {
            return;
        }
        let ROOT_FOLDER_ID = getIdFolderDriveFromURL(profile?.shared_folder_url_id);
        // setLoading(true);
        if (!ROOT_FOLDER_ID) {
            showErrorModal({
                content: t('messages.share_folder_invalid')
            });
            return;
        }
        const resTest = await googleAPIService.checkPermissionUploadById(ROOT_FOLDER_ID, profile?.email);
        if (!resTest) {
            showErrorModal({
                content: t('messages.permission_folder_fail')
            });
            return;
        }
        if (id) {
            const res = await flowResult(updateUserInfor({ data: { customer_id: data.customer_id }, id }));
            if (res) {
                await googleAPIService.renameFolder(data.customer_id, data.customer_id);
                hideModal('update-user');
                toastify('success', t('messages.update_success'));
                onFetch();
            }
        } else {
            const folderIdCus = await googleAPIService.createFolderInParent(data.customer_id, ROOT_FOLDER_ID);
            if (!folderIdCus) {
                showErrorModal({
                    content: t('messages.create_folder_error')
                });
                return;
            }
            // form.setValue('folder_url_id', folderIdCus);
            const res = await flowResult(createUser({ ...data, folder_url_id: folderIdCus }));
            if (res) {
                hideModal('create-user');
                toastify('success', t('messages.add_success'));
                onFetch();
            } else {
                await googleAPIService.deleteFolderByFolderId(folderIdCus);
            }
        }
    };

    const onCancel = () => {
        if (id) {
            hideModal('update-user');
        } else {
            hideModal('create-user');
        }
    };

    return (
        <Form {...form}>
            <form className='' onSubmit={form.handleSubmit(onSubmit)}>
                <div className='flex flex-col pt-[10px]'>
                    <FormField
                        control={form.control}
                        name='customer_id'
                        render={({ field }) => (
                            <FormItem className=''>
                                <FormLabelRequire>顧客ID</FormLabelRequire>
                                <div className='w-full'>
                                    <FormControl>
                                        <Input
                                            maxLength={50}
                                            type='text'
                                            {...field}
                                            onKeyPress={(e) => {
                                                if (/_/.test(e.key) || form.getValues('customer_id').length >= 255) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </div>
                            </FormItem>
                        )}
                    />
                    {id && (
                        <div className='mt-0'>
                            <FormLabel>利用者 LINE ID</FormLabel>
                            <div className='w-full bg-gray-300 py-2 px-4 rounded-md h-[40px] text-gray-500 text-[13px] flex items-center'>
                                {form.getValues('line_id')}
                            </div>

                        </div>
                    )}
                </div>
                <div className='mt-10 flex justify-end gap-8'>
                    <Button type='button' onClick={onCancel} variant='outline' className='w-[120px] text-main h-[39px]'>キャンセル</Button>
                    <Button variant='outline' disabled={form.formState.isSubmitting} type='submit' className='w-[120px] bg-main h-[39px] text-white'>{id ? '保存' : '追加'}</Button>
                </div>
            </form>
        </Form>
    );
});
