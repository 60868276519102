import App from '@/App';
import { ROUTES } from '@/configs/constants';
import { withAuthentication } from '@/hooks/withAuthentication';
import withNonAuthentication from '@/hooks/withNonAuthentication';
import AdminPanelLayout from '@/layouts/AdminPanelLayout';
import FormPanelLayout from '@/layouts/FormPanelLayout';
import {
    CompanyManager,
    FileManagement,
    LoginPage,
    NotFoundPage,
    PrivacyPolicyPage,
    UserManagementPage
} from '@/pages';
import { roleIdEnum } from '@/types/enums';
import { createBrowserRouter, redirect } from 'react-router-dom';

const AdminPanelLayoutWithAuthentication = withAuthentication(AdminPanelLayout);

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                index: true,
                loader: () => redirect(ROUTES.login.href)
            },
            {
                path: '/',
                element: <FormPanelLayout />,
                children: [
                    {
                        path: ROUTES.login.href,
                        element: <LoginPage />
                    }
                ]
            },
            {
                path: '/',
                children: [

                    {
                        path: ROUTES.privacyPolicy.href,
                        element: <PrivacyPolicyPage />
                    }
                ]
            },
            {
                path: '/',
                element: <AdminPanelLayoutWithAuthentication roles={[roleIdEnum.adminSystem]} />,
                children: [
                    {
                        path: ROUTES.companyManager.href,
                        element: <CompanyManager />
                    }
                ]
            },
            {
                path: '/',
                element: <AdminPanelLayoutWithAuthentication roles={[roleIdEnum.adminCompany]} />,
                children: [
                    {
                        path: ROUTES.userManagement.href,
                        element: <UserManagementPage />
                    },
                    {
                        path: ROUTES.fileManager.href,
                        element: <FileManagement />
                    }
                ]
            },
            {
                path: '*',
                element: (
                    <AdminPanelLayout>
                        <NotFoundPage />
                    </AdminPanelLayout>
                )

            }
        ]
    }
]);
