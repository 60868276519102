import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    RowData,
    TableOptions,
    useReactTable
} from '@tanstack/react-table';

import {
    TableRoot,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '../ui/TableUI';
import React from 'react';
import SelectPageSize from './SelectPageSize';
import { cn } from '@/utils/utils';
import PagingInfo from './PagingInfo';
import Paginate from '../pagination/Paginate';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/hooks/useStore';
import { SkeletonDataTable } from './SkeletonTable';
import { useTranslation } from 'react-i18next';
import { ArrowDownZAIcon, ArrowUpDownIcon, ArrowUpZAIcon } from 'lucide-react';
import { uploadStatus } from '@/types/enums';
import { Button } from '../common/Button';

import './style.scss';

declare module '@tanstack/react-table' {
    // eslint-disable-next-line
    interface ColumnMeta<TData extends RowData, TValue> {
        headerClassName: string,
        cellClassName: string
    }
}

interface TableProps<TData, TValue> extends Partial<TableOptions<TData>> {
    columns: ColumnDef<TData, TValue>[],
    data: TData[],
    containerClassName?: string,
    headerClassName?: string,
    cellClassName?: string,
    noData?: React.ReactNode,
    isManualAll?: boolean,
    showTableFooter?: boolean,
    showPagination?: boolean,
    showPagingInfo?: boolean,
    showSelectPageSize?: boolean,
    loadingData?: boolean,
    selectPageSizes?: number[],
    onClickRightButton?: Function,
    txtButtonRight?: string,
    imgBtnRignt?: string,
    variant?: 'primary' | 'link' | 'default' | 'submit' | 'menu' | 'destructive' | 'outline' | 'secondary' | 'ghost' | 'active' | 'notActive' | 'second' | null | undefined,
    fixOverText?: boolean
}

export default observer(function Table<TData, TValue>({
    columns,
    data,
    containerClassName,
    headerClassName,
    cellClassName,
    noData,
    isManualAll = true,
    showTableFooter = true,
    showPagination = true,
    showPagingInfo = true,
    showSelectPageSize = true,
    loadingData = false,
    selectPageSizes,
    onClickRightButton = () => {},
    txtButtonRight = undefined,
    imgBtnRignt = undefined,
    // select row
    enableRowSelection = false,
    variant = 'primary',
    fixOverText = false,
    ...props
}: TableProps<TData, TValue>) {
    // variables
    const columnSelect: ColumnDef<TData> = {
        accessorKey: 'select-box',
        header: ({ table }) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomePageRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler()
                }}
            />
        ),
        cell: ({ row }) => (
            <div className='px-1'>
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler()
                    }}
                />
            </div>
        ),
        enableSorting: false,
        minSize: 10,
        size: 10,
        maxSize: 10
    };

    // hooks
    const { t } = useTranslation();

    // store
    const { apiStore: { isLoadingTable } } = useStore();

    // init table
    const table = useReactTable({
        data,
        columns: !enableRowSelection ?
            columns :
            [
                columnSelect,
                ...columns
            ],
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection,
        ...(isManualAll ?
            {
                manualExpanding: true,
                manualFiltering: true,
                manualGrouping: true,
                manualPagination: true,
                manualSorting: true
            } :
            {}),
        ...props
    });

    const fixTextOver = (text: any): string => {
        if (!text) {
            return '';
        }
        const newText = text.toString();
        const first = newText.slice(0, 20);
        const end = newText.slice(newText.length - 10);
        const finalText = first + ' ... ' + end;
        return finalText;
    };

    function formatCellValue(value: any): any {
        if (!fixOverText) {
            return value;
        }
        const data = value.props.row.original.name;
        if (typeof data === typeof 'string' && data.length > 50) {
            const newData = fixTextOver(data);
            let newValue = value;
            newValue.props.row.original.name = newData;
            return newValue;
        }
        return value;
    }

    const getColorTableFiles = (statusFile: string | undefined) => {
        switch (statusFile) {
            case uploadStatus.ERROR_FILE:
                return 'bg-[#F8EEEE] border-b-[8px] border-b-bgInput ';
            case uploadStatus.FAIL:
                return 'bg-[#F8EEEE] border-b-[8px] border-b-bgInput ';
            case uploadStatus.DONE:
                return 'bg-[#EEF1F8] border-b-[8px] border-b-bgInput ';
            case uploadStatus.UPLOADING:
                return 'bg-[#EEF1F8] border-b-[8px] border-b-bgInput ';
            case uploadStatus.WAITING_REUP:
                return 'bg-[#EEF1F8] border-b-[8px] border-b-bgInput ';
            case uploadStatus.WAITING:
                return 'bg-[#EEF1F8]  border-b-[8px] border-b-bgInput';
            default:
                return 'bg-white ';
        }
    };
    return (
        <div className={containerClassName}>
            <div className='flex justify-between'>
                {
                    showSelectPageSize && (
                        <div className='flex mb-4'>
                            <SelectPageSize
                                pageSize={table.getState().pagination.pageSize}
                                setPagination={table.setPagination}
                                selectPageSizes={selectPageSizes}
                            />
                        </div>
                    )
                }
                {
                    txtButtonRight && (
                        <Button
                            variant={variant}
                            type='submit'
                            className='w-[150px] text-white rounded-lg '
                            onClick={() => onClickRightButton()}
                        >
                            {imgBtnRignt && <img src={imgBtnRignt} alt='Local Image' className='pr-[10px]' />}
                            {txtButtonRight}
                        </Button>
                    )
                }
            </div>
            <div className=''>
                <TableRoot>
                    <TableHeader>
                        {table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id} className='border-b-0'>
                                {headerGroup.headers.map((header, i) => {
                                    return (
                                        <TableHead
                                            key={header.id}
                                            className={cn(header.column.getCanSort() && 'cursor-pointer select-none', headerClassName, (i === 0) && 'rounded-l-lg', i === headerGroup.headers.length - 1 && 'rounded-r-lg')}
                                            style={{ width: header.getSize() }}
                                            onClick={header.column.getToggleSortingHandler()}
                                            title={
                                                header.column.getCanSort() ?
                                                    header.column.getNextSortingOrder() === 'asc' ?
                                                        t('table.sort.ascending') :
                                                        header.column.getNextSortingOrder() === 'desc' ?
                                                            t('table.sort.descending') :
                                                            t('table.sort.clear') :
                                                    undefined
                                            }
                                        >
                                            {
                                                header.isPlaceholder ?
                                                    null :
                                                    (
                                                        <div className={cn(
                                                            'flex items-center justify-start gap-1',
                                                            headerClassName,
                                                            header.column.columnDef.meta?.headerClassName
                                                        )}
                                                        >
                                                            {
                                                                flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )
                                                            }
                                                            {
                                                                header.column.getCanSort() &&
                                                                (
                                                                    {
                                                                        asc: <ArrowUpZAIcon className='inline-block w-4 h-4' />,
                                                                        desc: <ArrowDownZAIcon className='inline-block w-4 h-4' />
                                                                    }[header.column.getIsSorted() as string] ?? <ArrowUpDownIcon className='inline-block w-4 h-4' />
                                                                )
                                                            }
                                                        </div>
                                                    )
                                            }
                                        </TableHead>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    {
                        (!loadingData || (loadingData && !isLoadingTable)) && (
                            <TableBody>
                                {table.getRowModel().rows?.length ?
                                    (
                                        table.getRowModel().rows.map((row) => {
                                            const dataColStatus: any = row.getAllCells().find(e => e.column.id === 'Status')?.row.original;
                                            const statusUploadValue = dataColStatus?.uploaded || undefined;
                                            return (
                                                <TableRow
                                                    className={`min-h-[60px] ${getColorTableFiles(statusUploadValue)}`}
                                                    key={row.id}
                                                    data-state={row.getIsSelected() && 'selected'}
                                                >
                                                    {row.getVisibleCells().map(cell => (
                                                        <TableCell
                                                            key={cell.id}
                                                            className={cn('text-left', cellClassName, cell.column.columnDef.meta?.cellClassName, getColorTableFiles(statusUploadValue))}
                                                        >
                                                            {formatCellValue(flexRender(cell.column.columnDef.cell, cell.getContext()))}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            );
                                        })
                                    ) :
                                    (
                                        <TableRow>
                                            <TableCell colSpan={columns.length} className='h-24 text-center'>
                                                {noData ?? 'データはありません'}
                                            </TableCell>
                                        </TableRow>
                                    )}
                            </TableBody>
                        )
                    }
                    {
                        loadingData && isLoadingTable && <SkeletonDataTable numCols={table.getAllColumns().length} />
                    }
                </TableRoot>
            </div>
            {
                (!loadingData || (loadingData && !isLoadingTable)) && showTableFooter && table.getRowCount() > 0 && (
                    <div className='mt-4 flex justify-between items-center'>
                        <div>
                            {
                                showPagingInfo && (
                                    <PagingInfo
                                        pageIndex={table.getState().pagination.pageIndex}
                                        pageSize={table.getState().pagination.pageSize}
                                        totalRecords={table.getRowCount()}
                                        className='text-[13px]'
                                    />
                                )
                            }
                        </div>
                        <div>
                            {
                                showPagination && (
                                    <Paginate
                                        forcePage={table.getState().pagination.pageIndex}
                                        pageCount={table.getPageCount()}
                                        onPageChange={({ selected }) => table.setPageIndex(selected)}
                                        marginPagesDisplayed={2}
                                        size='xm'
                                    />
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
});

function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & React.HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate]);

    return (
        <input
            type='checkbox'
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    );
}
