export const LANGUAGES_SUPPORTED = {
    English: 'en',
    Vietnamese: 'vi'
} as const;

export type LanguagesSupported =
    (typeof LANGUAGES_SUPPORTED)[keyof typeof LANGUAGES_SUPPORTED];

export const STATUS = {
    Inactive: 0,
    Active: 1,
    WaitConfirm: 2
} as const;

export type Status = (typeof STATUS)[keyof typeof STATUS];

export const GENDER = {
    Male: 0,
    Female: 1
} as const;

export type Gender = (typeof GENDER)[keyof typeof GENDER];

export const fileTypeFormat = {
    PDF: 'application/pdf'
} as const;

export const roleIdEnum = {
    adminSystem: 0,
    adminCompany: 1,
    user: 2
} as const;

export type RoleIdType = (typeof roleIdEnum)[keyof typeof roleIdEnum];

export const uploadStatus = {
    WAITING: 'waiting',
    DONE: 'done',
    FAIL: 'fail',
    UPLOADING: 'uploading',
    ERROR_FILE: 'error_file',
    WAITING_REUP: 'waiting_reup'
} as const;
