import ApiService from './ApiService';

export default class AuthApi extends ApiService {
    login(payload) {
        return this.post('login', payload);
    }

    logout() {
        return this.post('logout');
    }

    getProfile() {
        return this.get('profile');
    }

    googleLogin(payload) {
        return this.post('google-auth', payload);
    }

    getMyInfor(payload) {
        return this.get('info', payload);
    }
}
