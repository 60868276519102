import { UserApi } from '@/apis';
import RootStore from '.';
import BaseStore from './BaseStore';
import { User } from '@/types/user';
import { flow, makeObservable, observable } from 'mobx';
import { GetUsersRequest, GetUsersResponse, UserRequestInfor, UserResponse } from '@/types/http-payload/user';
import { ResponseData } from '@/types/http';
import { TableState } from '@tanstack/react-table';

export default class UserStore extends BaseStore {
    users: User[] = [];
    user: User | null = null;
    api: UserApi;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            users: observable,
            user: observable,
            getListUsers: flow.bound,
            getUserDetail: flow.bound,
            createUser: flow.bound,
            updateStatusUSer: flow.bound,
            updateUserInfor: flow.bound,
            deleteUser: flow.bound,
            downloadORCode: flow.bound
        });
        this.api = new UserApi();
    }

    *getListUsers(searchParams: GetUsersRequest, paging?: TableState) {
        try {
            const payload = { ...this.convertPagingFromTableToRequest(paging ?? this.paging), ...searchParams };
            const res: ResponseData<GetUsersResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getUsers, payload);
            if (res.ok) {
                this.users = res.data.elements;
                this.paging = {
                    ...this.paging,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res.data.paginate)
                };
                this.totalRecord = res.data.paginate.totalRecord;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getUserDetail(payload) {
        try {
            const res: ResponseData<UserResponse> = yield this.rootStore.apiStore.call(this.api, this.api.getUserInfor, payload);
            if (res?.ok) {
                return res.data;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *createUser(payload: UserRequestInfor) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.createUser, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *updateStatusUSer(payload) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.updateStatusUser, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *updateUserInfor(payload) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.updateUser, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *deleteUser(payload) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(this.api, this.api.deleteUser, payload);
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *downloadORCode(payload) {
        try {
            const res: any = yield this.rootStore.apiStore.call(this.api, this.api.downloadQRCode, payload);
            return res;
        } catch (error) {
            return false;
        }
    }

    clean(): void {
        super.clean();
        this.users = [];
    }
}
