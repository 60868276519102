import { flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { CompanyApi } from '@/apis';
import { ResponseData } from '@/types/http';
import { Company, CompanyRequest, GetCompanyResponse, changeStatusRequest } from '@/types/company';
import { toastify } from '@/utils/toastify';
import { TableState } from '@tanstack/react-table';

export default class CompanyStore extends BaseStore {
    api: CompanyApi;
    listCompanies: Company[] = [];

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            getListCompany: flow.bound,
            listCompanies: observable,
            createCompany: flow.bound,
            changeStatus: flow.bound,
            deleteCompany: flow.bound,
            getDetailCompany: flow.bound,
            updateCompany: flow.bound
        });
        this.api = new CompanyApi();
    }

    *getListCompany(searchParams?: GetCompanyResponse, paging?: TableState) {
        try {
            const payload = { ...this.convertPagingFromTableToRequest(paging ?? this.paging), ...searchParams };
            const res: ResponseData<GetCompanyResponse> = yield this.rootStore.apiStore.call(
                this.api,
                this.api.getListCompany,
                payload
            );
            if (res?.ok) {
                this.listCompanies = res.data.elements;
                this.paging = {
                    ...this.paging,
                    sorting: paging?.sorting ?? [],
                    pagination: this.convertPaginationFromRequestToTable(res.data.paginate)
                };
                this.totalRecord = res.data.paginate.totalRecord;
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *createCompany(payload: CompanyRequest) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(
                this.api,
                this.api.createCompany,
                payload
            );
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return error;
        }
    }

    *changeStatus(id: string, payload: changeStatusRequest) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(
                this.api,
                this.api.changeStatusActived,
                { id, payload }
            );
            return res.ok || false;
        } catch (error) {
            return false;
        }
    }

    *deleteCompany(id: string) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(
                this.api,
                this.api.deteteCompanyById,
                id
            );
            return res.ok || false;
        } catch (error) {
            return false;
        }
    }

    *updateCompany(id: string, payload: CompanyRequest) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(
                this.api,
                this.api.updateDetailCompany,
                { id, payload }
            );
            if (res.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *getDetailCompany(id: string, hideLoading: boolean = false) {
        try {
            const res: ResponseData<any> = yield this.rootStore.apiStore.call(
                this.api,
                this.api.getCompanyDetailById,
                id,
                { hideLoading: hideLoading }
            );
            if (res?.ok) {
                return res.data || undefined;
            }
        } catch (error) {
            return undefined;
        }
    }

    clean(): void {
        super.clean();
        this.listCompanies = [];
    }
}
