import Breadcrumb from '@/components/common/Breadcrumb';
import Footer from '@/components/layout/Footer';
import Navbar from '@/components/layout/menu-bar/Navbar';
import Sidebar from '@/components/layout/menu-bar/Sidebar';
import { useStore } from '@/hooks/useStore';
import { getBreadcrumb, getTitlePage } from '@/utils/routes';
import { cn } from '@/utils/utils';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export default observer(function AdminPanelLayout({ children }: { children?: ReactNode }) {
    // hooks
    const { pathname } = useLocation();

    // variables
    const title = getTitlePage(pathname);
    const breadcrumb = getBreadcrumb(pathname);

    // store
    const {
        uiStore: { isOpenSidebar }
    } = useStore();

    return (
        <>
            <Sidebar />
            <main
                className={cn(
                    'min-h-[calc(100vh_-_56px)] bg-white transition-[margin-left] ease-in-out duration-300',
                    isOpenSidebar === false ? 'lg:ml-[90px]' : 'lg:ml-72'
                )}
            >
                <Navbar title={title} />
                <div>
                    <Breadcrumb breadcrumb={breadcrumb} />
                    <div className='border bg-bgInput text-card-foreground shadow border-none p-4 min-h-[calc(100vh-56px)]'>
                        {children ?? <Outlet />}
                    </div>
                </div>
            </main>
            {/* <footer
                className={cn(
                    'transition-[margin-left] ease-in-out duration-300',
                    isOpenSidebar === false ? 'lg:ml-[90px]' : 'lg:ml-72'
                )}
            >
                <Footer />
            </footer> */}
        </>
    );
});
