import { observer } from 'mobx-react-lite';
import { cn } from '@/utils/utils';
import { useTranslation } from 'react-i18next';
import { useStore } from '@/hooks/useStore';
import SidebarToggle from './_components/SidebarToggle';
import Menu from './_components/Menu';
import classNames from 'classnames';
import logo from '../../../assets/images/logo_red.png';

export default observer(function SideBar() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        uiStore: { isOpenSidebar }
    } = useStore();

    return (
        <aside
            className={cn(
                'fixed top-0 left-0 z-20 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300 bg-white backdrop-blur',
                isOpenSidebar === false ? 'w-[90px]' : 'w-72'
            )}
        >
            <SidebarToggle />

            <div className='relative h-full flex flex-col shadow-md '>
                {/* Brand top */}

                <div
                    className={classNames(
                        'bg-main transition-transform ease-in-out duration-300 h-[75px]  justify-center content-center items-center',
                        isOpenSidebar === false ? 'translate-x-0' : 'translate-x-0',
                        'bg-bg-header-menu-white bg-cover'
                    )}
                >
                    <div
                        className='flex flex-row justify-start pl-[16px] py-[14px]'
                    >
                        <div
                            className={cn(
                                'font-bold whitespace-nowrap transition-[transform,opacity,display] ease-in-out duration-300',
                                isOpenSidebar === false ?
                                    '-translate-x-96 opacity-0 hidden' :
                                    'translate-x-0 opacity-100'
                            )}
                        >
                            <img className='w-[70%] ' src={logo} />
                        </div>
                    </div>
                </div>
                <Menu isOpen={isOpenSidebar} />
            </div>
        </aside>
    );
});
