import { FileText, MenuIcon, PanelsTopLeft } from 'lucide-react';
import { Button } from '@/components/common/Button';
import {
    Sheet,
    SheetHeader,
    SheetContent,
    SheetTrigger,
    SheetTitle,
    SheetDescription,
    SheetClose
} from '@/components/common/Sheet';
import { ROUTES } from '@/configs/constants';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from './_components/Menu';
import logo from '../../../assets/images/logo_red.png';

interface WrapperSheetCloseProps {
    useSheetClose?: boolean
}

export const WrapperSheetClose = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & WrapperSheetCloseProps
>(({ children, useSheetClose, ...props }, ref) => (
    <div ref={ref} {...props}>
        {
            !useSheetClose ?
                (
                    <>{children}</>
                ) :
                (
                    <SheetClose asChild>{children}</SheetClose>
                )
        }
    </div>
));
WrapperSheetClose.displayName = 'WrapperSheetClose';

export default function SheetMenu() {
    // state
    const [isOpen, setOpen] = useState<boolean>(false);

    return (
        <Sheet open={isOpen} onOpenChange={setOpen}>
            <SheetTrigger className='lg:hidden ' asChild>
                <Button className='h-8' variant='outline' size='icon'>
                    <MenuIcon size={20} />
                </Button>
            </SheetTrigger>
            <SheetContent
                className='sm:w-72 max-w-[300px] h-full flex flex-col px-0 pb-0'
                side='left'
            >
                <SheetHeader className='bg-bg-header-menu-white bg-cover w-full h-[70px] flex justify-center'>
                    <img className='w-[70%] ml-[16px]' src={logo} />
                </SheetHeader>
                <Menu isOpen={isOpen} useSheetClose />
            </SheetContent>
        </Sheet>
    );
}
