import { Button } from '@/components/common/Button';
import { Input } from '@/components/common/Input';
import { useStore } from '@/hooks/useStore';
import { Edit, Trash2 } from 'lucide-react';
import { flowResult, toJS } from 'mobx';
import { useEffect, useState } from 'react';
import { UiChangeStatusCompany, UiCreateCompany, UiDeleteCompany, UiUpdateCompany } from './ui-dialog-company';
import { Company } from '@/types/company';
import { observer } from 'mobx-react-lite';
import { ColumnDef, TableState, Updater } from '@tanstack/react-table';
import Table from '@/components/table/Table';
import { Switch } from '@/components/common/Switch';
import { ACTIVE, DEFAULT_PAGINATION, DEFAULT_SELECT_PAGE_SIZES } from '@/configs/constants';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/common/Form';
import { useForm } from 'react-hook-form';
import yup from '@/services/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import addCompany from '../../assets/images/add-company.png';
import { t } from 'i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export default observer(
    function CompanyPage() {
        // store
        const {
            companyStore: { listCompanies, getListCompany, changeStatus, paging, totalRecord, clean, keepSearchParams, setObservable, defaultPaging },
            modalStore: { showAlertModal, showModal }
        } = useStore();

        // state
        const [searchState, setSearchState] = useState<Object>(keepSearchParams ?? '');

        // lifecycle
        useEffect(() => {
            if (keepSearchParams) {
                form.reset(keepSearchParams);
                onFetch(keepSearchParams, paging);
            } else {
                onChangeSearch(searchState);
            }
        }, []);

        const onFetch = (searchParams?: any, paging?: TableState) => {
            if (paging) {
                setObservable('paging', paging ?? null);
            }
            setObservable('keepSearchParams', toJS(searchParams));
            getListCompany(searchParams, paging);
        };

        const onChangeSearch = (searchParams: any) => {
            setSearchState(searchParams);
            onFetch(searchParams, defaultPaging);
        };

        const onChaneSwitch = async (id: number, isActived: boolean) => {
            showAlertModal({
                type: 'form',
                cancelButton: false,
                saveButton: false,
                title: '確認',
                content: (
                    <UiChangeStatusCompany
                        idCompany={id}
                        value={isActived}
                        onFetch={() => onFetch(keepSearchParams, paging)}
                    />
                )
            });
        };

        const handleCallBackDelete = (paging: TableState, currentListLength: number) => {
            let currentPage = { ...paging };
            if (currentListLength < 2 && currentPage.pagination.pageIndex > 0) {
                currentPage.pagination.pageIndex = currentPage.pagination.pageIndex - 1;
            };
            onFetch(keepSearchParams, currentPage);
        };

        const columns: ColumnDef<Company>[] = [
            {
                header: 'No',
                size: 50,
                enableSorting: false,
                cell: ({ row: { index } }) => (
                    <div>{paging.pagination.pageIndex * paging.pagination.pageSize + index + 1}</div>
                )
            },
            {
                accessorKey: 'id',
                header: '事業者ID',
                size: 150,
                enableSorting: false,
                cell: ({ row: { original } }) => (
                    <div>{original.id}</div>
                )
            },
            {
                accessorKey: 'company_name',
                header: '事業者名',
                size: 300,
                enableSorting: false,
                cell: ({ row: { original } }) => (
                    <div>{original.name}</div>
                )
            },
            {
                accessorKey: 'shared_folder_url_id',
                header: '共有Googleドライブフォルダー',
                size: 500,
                enableSorting: false,
                cell: ({ row: { original } }) => (
                    <div className=''>{original.shared_folder_url_id}</div>
                )
            },
            {
                accessorKey: 'address',
                header: '住所（郵便番号含む）',
                size: 500,
                enableSorting: false,
                cell: ({ row: { original } }) => (
                    <div>
                        {'〒' + original.post_code}
                        <br />
                        {original.address}
                    </div>
                )
            },

            {
                accessorKey: 'status',
                header: 'ステータス',
                size: 150,
                enableSorting: false,
                cell: ({ row: { original } }) => (
                    <div>
                        <Switch
                            data-tooltip-id={original.status === ACTIVE ? 'tootip-active' : 'tootip-inactive'}
                            checked={original?.status === ACTIVE ? true : false}
                            onCheckedChange={(checked) => {
                                onChaneSwitch(original.id, checked);
                            }}
                        >
                        </Switch>
                    </div>
                )
            },
            {
                header: 'アクション',
                size: 120,
                enableSorting: false,
                cell: ({ row: { original } }) => (
                    <div className='flex flex-row justify-center gap-2 items-center'>
                        <button
                            data-tooltip-id='tootip-edit'
                            onClick={() => showAlertModal({
                                type: 'form',
                                cancelButton: false,
                                saveButton: false,
                                title: t('words_title.edit'),
                                size: '4xl',
                                content: <UiUpdateCompany id={original.id} onFetch={() => onFetch(keepSearchParams, paging)} />
                            })}
                            type='button'
                            className=' p-2 bg-bgUnCheck rounded-full'
                        >
                            <Edit className='text-icon' size={16} />
                        </button>
                        <button
                            data-tooltip-id='tootip-delete'
                            onClick={() => showAlertModal({
                                type: 'form',
                                cancelButton: false,
                                saveButton: false,
                                title: t('words_title.confirm'),
                                content: (
                                    <UiDeleteCompany
                                        idCompany={original.id}
                                        onFetch={() => handleCallBackDelete(paging, listCompanies.length)}
                                    />
                                )
                            })}
                            type='button'
                            className=' p-2 bg-bgUnCheck rounded-full'
                        >
                            <Trash2 className='text-icon' size={16} />
                        </button>
                    </div>
                )
            }
        ];

        const onStateChange = (stateUpdater: Updater<TableState>) => {
            const newPaging = stateUpdater instanceof Function ? stateUpdater(paging) : stateUpdater;
            onFetch(searchState, newPaging);
        };

        const onClickCreateCompany = () => {
            showModal({
                type: 'form',
                cancelButton: false,
                saveButton: false,
                title: t('words_title.add_company'),
                size: '4xl',
                content: (
                    <UiCreateCompany onFetch={() => onFetch(keepSearchParams, paging)} />
                )
            });
        };
        const validateSchema = yup.object().shape({
            company_name: yup
                .string(),
            status: yup.string()
        });
        type FormData = yup.InferType<typeof validateSchema>;
        const initialValues = {
            company_name: ''
        };

        const form = useForm<FormData>({
            resolver: yupResolver(validateSchema),
            mode: 'onChange',
            defaultValues: initialValues
        });

        return (
            <div>
                <div className='flex flex-row w-full justify-between bg-white p-6 items-center rounded-xl'>
                    <Form {...form}>
                        <form className='flex flex-row w-full justify-between content-center items-center' onSubmit={form.handleSubmit(onChangeSearch)}>
                            <div className='w-1/2'>
                                <FormField
                                    control={form.control}
                                    name='company_name'
                                    render={({ field }) => (
                                        <FormItem>
                                            <Input placeholder='事業者名' type='text' {...field} />
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className=''>
                                <Button variant='second' type='submit' className='w-[150px] text-white rounded-lg '>{t('words_title.search')}</Button>
                            </div>
                        </form>
                    </Form>
                </div>
                <div className='bg-white rounded-xl mt-6 p-6'>
                    <Table
                        txtButtonRight={t('words_title.add_company')}
                        onClickRightButton={onClickCreateCompany}
                        imgBtnRignt={addCompany}
                        columns={columns}
                        data={listCompanies}
                        rowCount={totalRecord}
                        onStateChange={onStateChange}
                        state={paging}
                        selectPageSizes={DEFAULT_SELECT_PAGE_SIZES}
                        getRowId={row => row.id?.toString()}
                        cellClassName='border-b-[1px] border-b-bgInput'
                    />
                </div>
                <ReactTooltip
                    id='tootip-active'
                    place='top'
                    content='無効'
                />
                <ReactTooltip
                    id='tootip-inactive'
                    place='top'
                    content='有効'
                />
                <ReactTooltip
                    id='tootip-delete'
                    place='top'
                    content='削除'
                />
                <ReactTooltip
                    id='tootip-edit'
                    place='top'
                    content='編集'
                />
            </div>
        );
    }
);
