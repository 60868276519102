import * as React from 'react';
import { cn } from '@/utils/utils';
import { Dot, EyeIcon, EyeOffIcon } from 'lucide-react';
import { OTPInput, OTPInputContext } from 'input-otp';
import { Label } from './Label';
import { MAX_LENGTH_DEFAULT_INPUT } from '@/configs/constants';

// #region Input
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    isPreventCopy?: boolean,
    isPreventCut?: boolean,
    isTypingOnlyNumber?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, maxLength = MAX_LENGTH_DEFAULT_INPUT, type, isPreventCopy, isPreventCut, isTypingOnlyNumber, onCopy, ...props }, ref) => {
        const onTypingOnlyNumber = (e, maxLength) => {
            if (
                e.key === 'Backspace' ||
                e.key === 'Delete' ||
                e.key === 'Tab' ||
                e.key === 'Escape' ||
                e.key === 'Enter' ||
                (e.key === 'a' && e.ctrlKey === true) ||
                (e.key === 'c' && e.ctrlKey === true) ||
                (e.key === 'v' && e.ctrlKey === true) ||
                (e.key === 'x' && e.ctrlKey === true) ||
                (e.key === 'ArrowLeft' || e.key === 'ArrowRight')
            ) {
                return;
            }

            if (e.target.value.length >= maxLength) {
                e.preventDefault();
                return;
            }

            if (!(e.key >= '0' && e.key <= '9')) {
                e.preventDefault();
            }
        };

        const onPreventCopy = (e) => {
            e.preventDefault();
        };

        const onPreventCut = (e) => {
            e.preventDefault();
        };

        return (
            <input
                maxLength={maxLength}
                type={type}
                className={cn(
                    'flex h-[40px] w-full text-[13px] text-input-1 placeholder:text-txtHolder placeholder:font-normal rounded-lg bg-bgInput px-3 py-4 file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:border-active disabled:cursor-not-allowed disabled:opacity-50',
                    className
                )}
                ref={ref}
                placeholder='入力してください'
                onKeyDown={isTypingOnlyNumber ? e => onTypingOnlyNumber(e, maxLength) : undefined}
                onCopy={isPreventCopy ? onPreventCopy : onCopy}
                onCut={isPreventCut ? onPreventCut : undefined}
                {...props}
            />
        );
    }
);
Input.displayName = 'Input';

// #endregion

// #region Input with icon
export interface InputWithIconProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    icon?: React.ReactNode
}

const InputWithIcon = React.forwardRef<HTMLInputElement, InputWithIconProps>(
    ({ type, icon, className, ...props }, ref) => {
        return (
            <div className='relative w-full'>
                <Input
                    ref={ref}
                    type={type}
                    className={cn(icon && 'pr-10', className)}
                    {...props}
                />
                {icon}
            </div>
        );
    }
);
InputWithIcon.displayName = 'InputWithIcon';

// #endregion

// #region Input password
export interface PasswordInputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    onToggleIcon?: () => void
}

const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>(
    ({ type, onToggleIcon, ...props }, ref) => {
        return (
            <div className='relative'>
                <Input ref={ref} type={type} {...props} />
                {
                    type === 'password' ?
                        <EyeOffIcon className='absolute w-6 h-6 right-3 top-2 hover:cursor-pointer' onClick={onToggleIcon} /> :
                        <EyeIcon className='absolute w-6 h-6 right-3 top-2 hover:cursor-pointer' onClick={onToggleIcon} />
                }
            </div>
        );
    }
);
PasswordInput.displayName = 'PasswordInput';

// #endregion

// #region Input OTP
const InputOTP = React.forwardRef<
    React.ElementRef<typeof OTPInput>,
    React.ComponentPropsWithoutRef<typeof OTPInput>
>(({ className, containerClassName, ...props }, ref) => (
    <OTPInput
        ref={ref}
        containerClassName={cn(
            'flex items-center gap-2 has-[:disabled]:opacity-50 w-full',
            containerClassName
        )}
        className={cn('disabled:cursor-not-allowed', className)}
        {...props}
    />
));
InputOTP.displayName = 'InputOTP';

const InputOTPGroup = React.forwardRef<
    React.ElementRef<'div'>,
    React.ComponentPropsWithoutRef<'div'>
>(({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center', className)} {...props} />
));
InputOTPGroup.displayName = 'InputOTPGroup';

const InputOTPSlot = React.forwardRef<
    React.ElementRef<'div'>,
    React.ComponentPropsWithoutRef<'div'> & { index: number }
>(({ index, className, ...props }, ref) => {
    const inputOTPContext = React.useContext(OTPInputContext);
    const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

    return (
        <div
            ref={ref}
            className={cn(
                'relative flex xsm:h-16 xsm:w-16 h-12 w-12 text-input-form-1 font-semibold items-center justify-center border border-border-2 rounded-lg transition-all',
                isActive && 'z-10 border border-active',
                className
            )}
            {...props}
        >
            {char}
            {hasFakeCaret && (
                <div className='pointer-events-none absolute inset-0 flex items-center justify-center'>
                    <div className='h-4 w-px animate-caret-blink bg-foreground duration-1000' />
                </div>
            )}
        </div>
    );
});
InputOTPSlot.displayName = 'InputOTPSlot';

const InputOTPSeparator = React.forwardRef<
    React.ElementRef<'div'>,
    React.ComponentPropsWithoutRef<'div'>
>(({ ...props }, ref) => (
    <div ref={ref} role='separator' {...props}>
        <Dot />
    </div>
));
InputOTPSeparator.displayName = 'InputOTPSeparator';

// #endregion

// #region Input floating label

const FloatingInput = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, ...props }, ref) => {
        return <Input placeholder=' ' className={cn('peer pt-6 pb-1.75', className)} ref={ref} {...props} />;
    }
);
FloatingInput.displayName = 'FloatingInput';

const FloatingLabel = React.forwardRef<
    React.ElementRef<typeof Label>,
    React.ComponentPropsWithoutRef<typeof Label>
>(({ className, ...props }, ref) => {
    return (
        <Label
            className={cn(
                'text-[0.9375rem] leading-4 absolute start-3 top-2 z-10 origin-left scale-75 text-label-1 duration-300',
                'peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100',
                'peer-focus:top-2 peer-focus:-translate-y-0 peer-focus:scale-75',
                className
            )}
            ref={ref}
            {...props}
        />
    );
});
FloatingLabel.displayName = 'FloatingLabel';

type FloatingLabelInputProps = InputProps & {
    label?: string,
    icon?: React.ReactNode,
    required?: boolean
};

const FloatingLabelInput = React.forwardRef<
    React.ElementRef<typeof FloatingInput>,
    React.PropsWithoutRef<FloatingLabelInputProps>
>(({ id, label, icon, className, required, ...props }, ref) => {
    return (
        <div className='relative detect-autofill'>
            <FloatingInput
                ref={ref}
                id={id}
                className={cn(icon && 'pr-10', className)}
                {...props}
            />
            <FloatingLabel htmlFor={id} required={required}>{label}</FloatingLabel>
            {icon}
        </div>
    );
});
FloatingLabelInput.displayName = 'FloatingLabelInput';

// #endregion

export { Input, InputWithIcon, PasswordInput, InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator, FloatingLabelInput };
