import { flowResult } from 'mobx';
import { JwtPayload, Profile } from '@/types/auth';
import { jwtDecode } from 'jwt-decode';
import { AuthApi, CompanyApi, FileApi, UserApi } from '@/apis';

import { gapi } from 'gapi-script';
import { saveLocalStorage } from '@/utils/browsers';
import { toastify } from '@/utils/toastify';
import { FileNameUploadSplit } from '@/types/files';
import { keyStorage } from '@/configs/constants';
import RootStore from '@/stores';
import AuthStore from '@/stores/AuthStore';
import ModalStore from '@/stores/ModalStore';

class GoogleAPI {
    private static instance: GoogleAPI;
    private authInstance: typeof gapi.auth2.GoogleAuth | undefined = undefined;
    private authApi: AuthApi;
    private userApi: UserApi;
    private fileApi: FileApi;
    private authStore: AuthStore;
    private modalStore: ModalStore;

    private constructor() {
        this.authApi = new AuthApi();
        this.userApi = new UserApi();
        this.fileApi = new FileApi();
        this.authStore = new AuthStore(new RootStore());
        this.modalStore = new ModalStore(new RootStore());
    }

    public static getInstance(): GoogleAPI {
        if (!GoogleAPI.instance) {
            GoogleAPI.instance = new GoogleAPI();
        }
        return GoogleAPI.instance;
    }

    public async initClient(): Promise<void> {
        return new Promise((resolve, reject) => {
            gapi.load('client:auth2', () => {
                gapi.client
                    .init({
                        apiKey: process.env.REACT_APP_API_KEY,
                        clientId: process.env.REACT_APP_CLIENT_ID,
                        discoveryDocs: [process.env.REACT_APP_DISCOVERY_DOCS as string],
                        scope: process.env.REACT_APP_SCOPES
                    })
                    .then(() => {
                        this.authInstance = gapi.auth2.getAuthInstance();
                        if (this.authInstance.isSignedIn.get()) {
                            const authData = JSON.stringify(this.authInstance.currentUser.get().getAuthResponse());
                            localStorage.setItem('googleAuth', authData);
                        }
                        resolve();
                    })
                    .catch((error) => {
                        console.error('Error initializing Google API client', error);
                        reject(error);
                    });
            });
        });
    }

    public async signIn(googleCallBackFn: Function): Promise<Profile | undefined> {
        if (!this.authInstance) {
            return undefined;
        }

        return this.authInstance.signIn().then(
            (res) => {
                const idToken = res.getAuthResponse().id_token;
                if (idToken) {
                    const userInfo = jwtDecode<JwtPayload>(idToken);
                    const { email, name, picture } = userInfo;
                    return this.authGGEmail(googleCallBackFn, email as string, name as string, picture as string);
                }
            },
            (error) => {
                console.error('Error signing in', error);
                return undefined;
            }
        );
    }

    public async signInOfflineAccess() {
        this.authInstance
            .grantOfflineAccess({
                clientId: process.env.REACT_APP_CLIENT_ID,
                redirect_uri: process.env.REACT_APP_REDIRECT_URI,
                response_type: 'code',
                scope: process.env.REACT_APP_SCOPES,
                access_type: 'offline',
                prompt: 'consent'
            })
            .then(
                (res: any) => {
                    console.log('Google signed in: ', res);
                },
                (error: any) => {
                    console.error('Error signing in', error);
                }
            );
    }

    public async checkPermissionUploadById(folderId: string | undefined, email: string | undefined): Promise<boolean> {
        let per = false;
        if (!folderId || !email) {
            console.log('no folderId');
            return per;
        }
        if (!gapi || !gapi.client || !gapi.client.drive) {
            console.error('GAPI chưa được khởi tạo hoặc chưa cài đặt drive API');
            return per;
        }
        await gapi.client.drive.permissions
            .list({
                fileId: folderId,
                fields: 'permissions(id,emailAddress,role,type)'
            })
            .then(
                (response) => {
                    const permissions = response.result.permissions;
                    // console.log('Danh sách quyền:', permissions);
                    const rolesEdit = ['owner', 'writer'];
                    const user = permissions.find(e => e.emailAddress === email);
                    if (user) {
                        if (rolesEdit.includes(user.role)) {
                            per = true;
                        }
                    }
                },
                (error) => {
                    console.error('Lỗi khi lấy danh sách quyền:', error);
                }
            );
        return per;
    }

    private async getFolderId(cusId: string): Promise<string | undefined> {
        const res: any = await this.fileApi.getFolderIDCustomer(cusId);
        if (res && res.data) {
            const { folder_url_id } = res.data.data;
            if (folder_url_id) {
                return folder_url_id;
            } else return undefined;
        } else return undefined;
    }

    public async deleteFolder(cusId: string, id: number): Promise<boolean> {
        if (!cusId || !id) {
            return false;
        }
        try {
            const folder_url_id = await this.getFolderId(cusId);
            const resDel: any = await this.userApi.deleteUser({ id });
            if (resDel && resDel.data.ok) {
                if (folder_url_id) {
                    try {
                        await gapi.client.drive.files.delete({
                            fileId: folder_url_id
                        });
                    } catch (error) {

                    }
                }
                return true;
            } else return false;
        } catch (error) {
            return false;
        }
    }

    public async deleteFolderByFolderId(folderId: string) {
        try {
            await gapi.client.drive.files.delete({
                fileId: folderId
            });
        } catch (error) {
            return false;
        }
    }

    public async renameFolder(cusId: string | undefined, newCusId: string): Promise<boolean> {
        if (!cusId || !newCusId) {
            console.error('Folder ID and new name are required to rename folder');
            return false;
        }

        try {
            const folderId = await this.getFolderId(cusId);
            if (folderId) {
                try {
                    const response = await gapi.client.drive.files.update({
                        fileId: folderId,
                        resource: {
                            name: newCusId // Đặt tên mới cho thư mục
                        },
                        fields: 'id, name'
                    });

                    if (response.status === 200) { // 200 OK indicates success
                        console.log(`Folder renamed to "${response.result.name}" with ID ${response.result.id}.`);
                        return true;
                    } else {
                        console.warn(`Unexpected status code: ${response.status}`);
                        return false;
                    }
                } catch (error) {
                    return false;
                }
            } else return false;
        } catch (error) {
            console.error('Error renaming folder', error);
            return false;
        }
    }

    public async authGGEmail(googleCallBackFn: Function, email: string, name?: string, picture?: string): Promise<Profile | undefined> {
        // Implement your authentication logic here
        const res: any = await flowResult(googleCallBackFn({ email: email, ...name && { name: name } }));
        if (res && res.ok) {
            const { data } = res;
            if (data) {
                picture && saveLocalStorage(keyStorage.AVATAR, picture);
                const { user, token } = data;
                saveLocalStorage(keyStorage.TOKEN, token);
                let newUser: Profile = { ...user, avatar: picture, token: token };
                saveLocalStorage(keyStorage.PROFILE, JSON.stringify(newUser));
                return newUser;
            } else return undefined;
        } else return undefined;
    }

    public async checkFolderExist(folderId: string | undefined, parentFolderId: string): Promise<boolean> {
        if (!folderId) return false;

        try {
            const response = await gapi.client.drive.files.list({
                q: `'${parentFolderId}' in parents and mimeType = 'application/vnd.google-apps.folder' and trashed = false`,
                fields: 'files(id)'
            });

            const folders = response.result.files;

            // Kiểm tra xem folderId có nằm trong danh sách folder con của thư mục cha
            const folderExists = folders.some(folder => folder.id === folderId);
            return folderExists;
        } catch (error: any) {
            if (error.status === 404) {
                return false; // Folder không tồn tại
            }
            throw error; // Các lỗi khác ngoài 404 sẽ được ném ra
        }
    }

    public async checkFileExist(nameFile: string, parentId: string): Promise<string | undefined> {
        try {
            const query = `'${parentId}' in parents and name = '${nameFile}' and trashed = false`;
            const response = await gapi.client.drive.files.list({
                q: query,
                fields: 'files(id, name)'
            });
            if (response.result.files && response.result.files.length > 0) {
                return response.result.files[0].id;
            } else return undefined;
        } catch (error) {
            return undefined;
        }
    };

    public async createFolderInParent(folderName: String, parentId: String): Promise<string | undefined> {
        try {
            const response = await gapi.client.drive.files.create({
                resource: {
                    name: folderName,
                    mimeType: 'application/vnd.google-apps.folder',
                    parents: [parentId]
                },
                fields: 'id'
            });
            return response.result.id || undefined;
        } catch (error) {
            return undefined;
        }
    };

    public async findFolderByNameAndParentId(parentId: String, name: String) {
        try {
            const response = await gapi.client.drive.files.list({
                q: `mimeType='application/vnd.google-apps.folder' and name='${name}' and '${parentId}' in parents and trashed=false`,
                fields: 'files(id, name)'
            });

            const folder = response.result.files.find(
                file => file.name === name
            );

            if (folder) {
                return folder ? folder.id : null;
            } else {
                const newFolderId = await this.createFolderInParent(name, parentId);
                return newFolderId;
            }
        } catch (error) {
            console.error('Error fetching folder ID:', error);
            return null;
        }
    };

    public async checkFolderAccess(folderId: string | undefined): Promise<boolean> {
        if (!folderId) {
            return false;
        }

        try {
            const response = await gapi.client.drive.files.get({
                fileId: folderId,
                fields: 'name'
            });
            return response.result.name ? true : false; // Trả về tên thư mục nếu tồn tại
        } catch (error: any) {
            console.error('Error fetching folder name:', error);
            return false; // Trả về null nếu có lỗi (ví dụ: không tìm thấy thư mục)
        }
    }

    public async getAccessToken(): Promise<string | undefined> {
        try {
            const GoogleAuth = gapi.auth2.getAuthInstance();

            // Đảm bảo người dùng đã đăng nhập
            if (!GoogleAuth.isSignedIn.get()) {
                await GoogleAuth.signIn();
            }

            // Lấy access token
            const currentUser = GoogleAuth.currentUser.get();
            const accessToken = currentUser.getAuthResponse().access_token;

            return accessToken;
        } catch (error) {
            console.error('Error fetching access token:', error);
            return undefined; // Trả về null nếu có lỗi
        }
    }

    public async checkFileNameInput(name: string): Promise<boolean> {
        if (!name) {
            return false;
        }
        if (name.length > 255) {
            return false;
        }
        const dataSplit: FileNameUploadSplit | undefined = await this.splitNameFile(name);
        if (!dataSplit) {
            return false;
        } else {
            const folderMonth = dataSplit.monthFolder;
            const regex = /^\d{4}(0[1-9]|1[0-2])$/;
            return regex.test(folderMonth);
        }
    }

    public async splitNameFile(name: string): Promise<FileNameUploadSplit | undefined> {
        const regex = /^[^_]+_\d{6}_.*\.pdf$/;
        if (!name || !regex.test(name)) return undefined;
        const indexComma = name.lastIndexOf('.');
        const baseName = name.slice(0, indexComma);
        const extension = name.slice(indexComma);
        const subParts = baseName.split('_');
        if (subParts.length >= 3) {
            const firstPart = subParts[0];
            const secondPart = subParts[1];
            const thirdPart = subParts.slice(2).join('_') + (extension ? '.' + extension : '');
            const data: FileNameUploadSplit = {
                userFolder: firstPart,
                monthFolder: secondPart,
                fileName: thirdPart
            };
            return data;
        } else {
            return undefined;
        }
    };
}

export default GoogleAPI;
