import React from 'react';

const PolicyPage: React.FC = () => {
    return (
        <div className='max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg'>
            <div className='text-2xl font-bold mb-4'>プライバシーポリシー</div>

            <div className='text-lg mb-4'>
                このプライバシーポリシー（以下「本ポリシー」といいます）は、企業と最終ユーザーを対象とした請求書管理システム（以下「本サービス」といいます）において、ユーザーのGoogle Driveを用いて請求書を保存し、Googleアカウントを通じてLINEアプリケーションを介して最終ユーザーへ通知を送信する際に収集される情報、利用方法、及びその保護に関する方針を定めたものです。
            </div>

            <div className='text-xl font-semibold mb-3'>1. 情報の収集と利用</div>

            <div className='mb-4'>
                本サービスでは、以下の情報を収集し、利用します。
            </div>

            <div className='ml-6 mb-4'>
                <div className='font-semibold mb-2'>1.1 Googleアカウント情報</div>
                <ul className='list-disc ml-5'>
                    <li>本サービスは、Googleアカウントでのログインを通じて、ユーザーの氏名、メールアドレス、及びGoogle Driveへのアクセス権限を取得します。</li>
                    <li>これらの情報は、ユーザー認証、データの保存、及びサービスの提供に使用されます。</li>
                </ul>
            </div>

            <div className='ml-6 mb-4'>
                <div className='font-semibold mb-2'>1.2 Google Drive情報</div>
                <ul className='list-disc ml-5'>
                    <li>ユーザーのGoogle Driveに請求書を保存するため、ユーザーのGoogle Driveへの読み取りおよび書き込み権限を求めます。</li>
                    <li>本サービスは、ユーザーの許可を得て、指定されたフォルダにのみ請求書を保存し、その他のファイルやデータにはアクセスしません。</li>
                </ul>
            </div>

            <div className='ml-6 mb-4'>
                <div className='font-semibold mb-2'>1.3 LINE通知情報</div>
                <ul className='list-disc ml-5'>
                    <li>本サービスは、請求書の発行や支払期限などの通知をLINEアプリケーションを通じてユーザーに送信します。その際、LINEユーザーIDなどの情報を収集することがあります。</li>
                    <li>この情報は、通知機能の提供にのみ使用され、他の目的には使用されません。</li>
                </ul>
            </div>

            <div className='text-xl font-semibold mb-3'>2. 情報の共有と第三者提供</div>

            <div className='mb-4'>
                本サービスは、以下の場合を除き、ユーザーの情報を第三者に提供することはありません。
                <ol className='list-decimal ml-5 mt-2'>
                    <li>ユーザーの事前の同意がある場合</li>
                    <li>法令に基づく場合</li>
                    <li>人の生命、身体または財産の保護のために必要であり、ユーザーの同意を得ることが困難である場合</li>
                </ol>
            </div>

            <div className='text-xl font-semibold mb-3'>3. GoogleアカウントおよびDrive情報の保護</div>

            <div className='mb-4'>
                本サービスは、ユーザーのGoogleアカウント情報およびGoogle Drive内のデータを保護するために、最新のセキュリティ対策を講じます。
                <br />
                本サービスは、Google OAuth 2.0を使用してユーザーのGoogleアカウントにアクセスし、ユーザーの個人情報やファイルに対して不正アクセスが行われないようにします。
            </div>

            <div className='text-xl font-semibold mb-3'>4. データの保存期間</div>

            <div className='mb-4'>
                ユーザーのGoogle Driveに保存された請求書データは、ユーザーが削除を行うか、サービスの利用を停止するまで保存されます。本サービスが保存しているその他の情報についても、サービス提供に必要な期間を超えて保持することはありません。
            </div>

            <div className='text-xl font-semibold mb-3'>5. ユーザーの権利</div>

            <div className='mb-4'>
                ユーザーは、以下の権利を有します。
                <ol className='list-decimal ml-5 mt-2'>
                    <li>自身の個人情報へのアクセスおよびその修正</li>
                    <li>データの削除およびサービスの利用停止</li>
                    <li>Google Driveへのアクセス権限の取り消し</li>
                </ol>
                これらの権利を行使する場合は、以下の問い合わせ先までご連絡ください。
            </div>

            <div className='text-xl font-semibold mb-3'>6. お問い合わせ先</div>

            <div className='mb-4'>
                本ポリシーに関するご質問やお問合せは、以下のメールアドレスまでご連絡ください。
                <br />
                E-mail:
                {' '}
                <span className='text-blue-600 underline'>bangpham.dss@gmail.com</span>
            </div>

            <div className='text-xl font-semibold mb-3'>7. 本ポリシーの改定</div>

            <div className='mb-4'>
                本ポリシーは、サービス内容の変更や法令の改正に伴い、随時変更されることがあります。変更後のポリシーは、本サービスのウェブサイト上に掲示した時点で効力を生じます。
            </div>

            <div className='text-gray-600 text-sm'>
                制定日: 2024年10月10日
            </div>
        </div>
    );
};

export default PolicyPage;
