import { action, makeObservable, observable } from 'mobx';
import RootStore from '.';
import { PaginationState, TableState } from '@tanstack/react-table';
import { DEFAULT_PAGINATION } from '@/configs/constants';
import { PagingReMath, PagingRequest, PagingResponse } from '@/types/http';

interface SetObservableProps {
    isMergeObject?: boolean
}
export default class BaseStore {
    rootStore: RootStore;
    defaultPaging: TableState = {
        pagination: DEFAULT_PAGINATION,
        sorting: [{}]
    } as TableState;

    totalRecord: number = 0;
    paging: TableState = this.defaultPaging;

    keepSearchParams?: Object;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            totalRecord: observable,
            setObservable: action.bound,
            paging: observable,
            keepSearchParams: observable,
            clean: action.bound
        });
        this.rootStore = rootStore;
    }

    setObservable(
        field: string,
        value: unknown,
        options?: SetObservableProps
    ) {
        this[field] = options?.isMergeObject && value instanceof Object ?
            { ...this[field], ...value } :
            value;
    }

    convertPagingFromTableToRequest(paging: TableState): PagingRequest {
        return {
            page: paging.pagination.pageIndex + 1,
            size: paging.pagination.pageSize,
            sorting: paging.sorting.filter(sort => sort.id).map(sort => ({
                sortKey: sort.id,
                sortDir: sort.desc ? 'DESC' : 'ASC'
            }))
        };
    }

    convertPaginationFromRequestToTable(pagination: PagingResponse): PaginationState {
        return {
            pageIndex: pagination.page - 1,
            pageSize: pagination.size
        };
    }

    clean() {
        this.paging = this.defaultPaging;
        this.keepSearchParams = undefined;
    }

    convertPagingReMatch(paginate: PagingResponse): PagingReMath {
        const toMax = paginate.page * paginate.size + 10;

        return {
            totalRecord: paginate.totalRecord,
            from: paginate.page * paginate.size + 1,
            to: toMax >= paginate.totalRecord ? paginate.totalRecord : toMax,
            totalPage: paginate.totalPage
        };
    }
}
