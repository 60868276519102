import { FileApi } from '@/apis';
import RootStore from '.';
import BaseStore from './BaseStore';
import { User } from '@/types/user';
import { flow, makeObservable, observable } from 'mobx';
import { GetUsersRequest, GetUsersResponse, UserRequestInfor, UserResponse } from '@/types/http-payload/user';
import { ResponseData } from '@/types/http';
import { TableState } from '@tanstack/react-table';
import { BodyPostNewFile, getCustomerDetailResponse } from '@/types/files';

export default class FileStore extends BaseStore {
    api: FileApi;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            getCustomerFolderId: flow.bound,
            postNewFile: flow.bound
        });
        this.api = new FileApi();
    }

    *getCustomerFolderId(customerId) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getFolderIDCustomer, customerId, { hideLoading: true });
            if (res.ok) {
                const data: getCustomerDetailResponse = {
                    id: res.data.id,
                    folder_url_id: res.data.folder_url_id,
                    customer_id: res.data.customer_id,
                    company_id: res.data.company_id,
                    status: res.data.status,
                    delete_flag: res.data.delete_flag
                };
                return data;
            }
        } catch (error) {
            return null;
        }
    }

    *postNewFile(payload: BodyPostNewFile) {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(
                this.api,
                this.api.postNewFileUploaded,
                payload,
                { hideLoading: true }
            );
            if (res.ok) {
                return true;
            }
        } catch (error) {
            return false;
        }
    }

    clean(): void {
        super.clean();
    }
}
