import { URL_CONFIG } from '@/configs/environment';
import axios from 'axios';

const axiosApi = axios.create({
    baseURL: URL_CONFIG.API.url + '/rest/api/v1/system',
    headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Pragma': 'no-cache'
    },
    timeout: 10000
});

export { axios, axiosApi };
