import axios from 'axios';
import ApiService from './ApiService';
import { keyStorage } from '@/configs/constants';

export default class AuthApi extends ApiService {
    constructor() {
        super('customers');
    }

    getUsers(payload) {
        return this.get('', payload);
    }

    getUserInfor(payload) {
        const { id } = payload;
        return this.get(`${id}`);
    }

    createUser(payload) {
        return this.post('', { customer_id: payload.customer_id, folder_url_id: payload.folder_url_id });
    }

    updateUser(payload) {
        const { id, data } = payload;
        return this.put(`${id}`, data);
    }

    updateStatusUser(payload) {
        const { id, isActive } = payload;
        return this.put(`status/${id}`, { status: isActive });
    }

    downloadQRCode(payload) {
        const { id } = payload;
        // return axios.get(`${process.env.REACT_APP_API_URL}/rest/api/v1/system/customers/download-qr-code/${id}`, {
        //     responseType: 'blob',
        //     headers: {
        //         Authorization: `${localStorage.getItem(keyStorage.TOKEN)}` // Thêm token vào header
        //     }
        // });
        return this.get(`download-qr-code/${id}`, { responseType: 'blob' });
    }

    deleteUser(payload) {
        const { id } = payload;
        return this.delete(`${id}`);
    }
}
