import { LogOutIcon, UserIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/components/common/DropdownMenu';
import Tooltip from '@/components/common/Tooltip';
import { Button } from '@/components/common/Button';
import Avatar from '@/components/common/Avatar';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { getStorage } from '@/utils/browsers';
import { keyStorage } from '@/configs/constants';

export default observer(function UserAvatar() {
    // hooks
    const { t } = useTranslation();

    // store
    const {
        authStore: { logout }
    } = useStore();

    const profile = getStorage(keyStorage.PROFILE, true);
    const avatar = getStorage(keyStorage.AVATAR);
    return (
        <DropdownMenu>
            <Tooltip
                content={t('words_title.profile')}
                triggerAsChild={true}
                side='bottom'
                align='end'
            >
                <DropdownMenuTrigger asChild>
                    <div className='flex justify-center items-center cursor-pointer'>
                        <Button variant='outline' className='relative h-8 w-8 rounded-full'>
                            <Avatar src={avatar} />
                        </Button>
                        <div className='ml-[16px] text-[13px] truncate max-w-40'>{profile?.name}</div>
                    </div>

                </DropdownMenuTrigger>
            </Tooltip>

            <DropdownMenuContent className='w-56' align='end' forceMount>
                <DropdownMenuLabel className='font-normal'>
                    <div className='flex flex-col space-y-1'>
                        <p className='text-sm font-medium leading-none max-w-50 truncate'>
                            {profile?.name}
                        </p>
                        <p className='text-xs leading-none text-muted-foreground'>
                            {profile?.email}
                        </p>
                    </div>
                </DropdownMenuLabel>
                {/* <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem className="hover:cursor-pointer" asChild>
            <Link to="/account" className="flex items-center">
              <UserIcon className="w-4 h-4 mr-3 text-muted-foreground" />
              {t("words_title.account")}
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator /> */}
                <DropdownMenuItem className='hover:cursor-pointer text-red400 mt-2 border-t-[1px]' onClick={logout}>
                    <LogOutIcon className='w-4 h-4 mr-3' />
                    {t('words_title.sign_out')}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
});
