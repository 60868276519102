import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import googleLg from '../../assets/images/google-logo.png';
import { keyStorage, ROUTES } from '@/configs/constants';
import { flowResult } from 'mobx';
import { roleIdEnum } from '@/types/enums';
import { toastify } from '@/utils/toastify';
import { getStorage, saveLocalStorage } from '@/utils/browsers';
import { Button } from '@/components/common/Button';
import { FileIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Profile } from '@/types/auth';
import GoogleAPI from '@/services/drive';
import { t } from 'i18next';
import logo from '../../assets/images/logo_red.png';

interface JwtPayload {
    email: string,
    email_verified: boolean,
    name: string,
    picture: string,
    given_name: string,
    family_name: string
}
export default observer(function LoginPage() {
    const navigate = useNavigate();
    const {
        authStore: { setAvatar, setToken, setProfile, googleLogin },
        modalStore: { showErrorModal }
    } = useStore();
    const [currentCode, setCurrentCode] = useState<string | undefined>(undefined);

    // useEffect(() => {
    //     if (window.location.hash && window.location.hash !== currentCode) {
    //         const codeReciver = window.location.hash;
    //         setCurrentCode(codeReciver);
    //         const code = codeReciver.slice(6);
    //         handleAuthCodeGG(code);
    //     }
    // }, [window.location.hash]);

    // const handleAuthCodeGG = async (code: string) => {
    //     const res = await flowResult(exchangeAuthTokenGG(code));
    //     if (res) {
    //         res === roleIdEnum.adminSystem ?
    //             navigate(ROUTES.companyManager.href) :
    //             (res === roleIdEnum.adminCompany ?
    //                 navigate(ROUTES.userManagement.href) :
    //                 toastify('error', 'You not have permission to access this site!'));
    //     }
    // };

    useEffect(() => {
        checkAuth();
        const initGoogleAPI = async () => {
            const googleAPIService = GoogleAPI.getInstance();
            await googleAPIService.initClient();
        };
        initGoogleAPI();
    }, []);

    const handleSignInGG = async () => {
        const checkAuthValue: boolean | void = await checkAuth(true);
        if (checkAuthValue === true) {
            return;
        }
        const googleAPIService = GoogleAPI.getInstance();
        const res = await googleAPIService.signIn(googleLogin);
        if (res && res.token) {
            if (res.role_id || res.role_id === roleIdEnum.adminSystem) {
                setAvatar(res.avatar!);
                setToken(res.token!);
                setProfile(res);
                if (res.role_id === roleIdEnum.adminSystem) {
                    navigate(ROUTES.companyManager.href);
                } else if (res.role_id === roleIdEnum.adminCompany) {
                    navigate(ROUTES.userManagement.href);
                }
            } else {
                showErrorModal({
                    content: t('messages.permission_access_cms_fail')
                });
            }
        }
    };

    const checkAuth = async (isFromBtnLogin = false): Promise<boolean | void> => {
        const token = getStorage(keyStorage.TOKEN);
        const profile: Profile = getStorage(keyStorage.PROFILE, true);
        if (token && profile && profile.email) {
            setToken(token);
            setProfile(profile);
            const role_id = profile?.role_id;
            if (role_id === roleIdEnum.adminSystem) {
                navigate(ROUTES.companyManager.href);
            } else if (role_id === roleIdEnum.adminCompany) {
                navigate(ROUTES.userManagement.href);
            }
            if (isFromBtnLogin) {
                return true;
            }
        }
    };

    return (
        <div className='w-full h-full flex justify-center items-center'>
            <div className='w-[544px] h-[351px] flex items-center justify-center flex-col rounded-xl bg-white'>
                <img src={logo} className='w-[90%] container' />
                <h1 className='m-8 text-[32px] font-bold'>{t('general.brand_name')}</h1>
                <Button
                    variant='outline'
                    onClick={handleSignInGG}
                    className='flex items-center rounded-xl shadow-md px-5 w-[85%] justify-center h-[65px] border-[1px] border-gray-600'
                >
                    <img src={googleLg} alt='Google Logo' className='w-[26px] h-[26px] mr-4' />
                    <span className='mt-1 text-[22px] font-normal text-gray-700'>
                        {t('messages.google_login')}
                    </span>
                </Button>
            </div>
        </div>
    );
});
