import { action, flow, makeObservable, observable } from 'mobx';
import RootStore from '.';
import BaseStore from './BaseStore';
import { getStorage, removeStorage, saveLocalStorage } from '@/utils/browsers';
import { AuthApi } from '@/apis';
import { keyStorage, ROUTES } from '@/configs/constants';
import { toastify } from '@/utils/toastify';
import { t } from 'i18next';
import { JwtPayload, Profile } from '@/types/auth';
import { ResponseData, ResponseDataExchangeIdTokenGG } from '@/types/http';
import {
    GoogleLoginPostRequest,
    LoginPostRequest,
    LoginPostResponse
} from '@/types/http-payload/auth';
import { FolderUser } from '@/types/files';
import { gapi } from 'gapi-script';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getNavigate } from '@/services/navigationService';

export default class AuthStore extends BaseStore {
    profile: Profile | undefined = JSON.parse(getStorage(keyStorage.PROFILE));
    token: string = getStorage(keyStorage.TOKEN);
    api: AuthApi;
    avatar: any = getStorage(keyStorage.AVATAR);
    listUsers?: FolderUser[];
    access_token?: string;
    refresh_token?: string;

    constructor(rootStore: RootStore) {
        super(rootStore);
        makeObservable(this, {
            profile: observable,
            token: observable,
            avatar: observable,
            listUsers: observable,
            access_token: observable,
            refresh_token: observable,
            clearAuthentication: action.bound,
            setAvatar: action.bound,
            setToken: action.bound,
            setProfile: action.bound,
            login: flow.bound,
            googleLogin: flow.bound,
            logout: action.bound,
            exchangeAuthTokenGG: flow.bound,
            getTokenGG: flow.bound,
            getMyInfor: flow.bound
        });
        this.api = new AuthApi();
    }

    *login(payload: LoginPostRequest) {
        try {
            const res: ResponseData<LoginPostResponse> =
                yield this.rootStore.apiStore.call(this.api, this.api.login, payload);
            if (res?.ok) {
                this.token = res.data.token;
                saveLocalStorage(keyStorage.TOKEN, res.data.token);
                toastify('success', t('messages.sign_in_success'));
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    setAvatar(avatar: string) {
        this.avatar = avatar;
    }

    setToken(token: string) {
        this.token = token;
    }

    setProfile(profile: Profile) {
        this.profile = profile;
    }

    *googleLogin(payload: GoogleLoginPostRequest) {
        try {
            const res: ResponseData<LoginPostResponse> = yield this.rootStore.apiStore.call(this.api, this.api.googleLogin, payload);
            if (res.ok) {
                this.token = res.data.token;
                this.profile = {
                    id: res.data.user.id,
                    name: payload.name,
                    email: res.data.user.email,
                    role_id: res.data.user.role_id,
                    shared_folder_url_id: res.data.user.shared_folder_url_id,
                    company_id: res.data.user.company_id
                };
                saveLocalStorage(keyStorage.TOKEN, res.data.token);
                saveLocalStorage(keyStorage.PROFILE, JSON.stringify(this.profile!));
                toastify('success', t('messages.sign_in_success'));
                // return res.data.user.role_id;
                return res;
            } else {
                removeStorage([keyStorage.TOKEN, keyStorage.AVATAR, keyStorage.PROFILE]);
            }
        } catch (error) {
            removeStorage([keyStorage.TOKEN, keyStorage.AVATAR, keyStorage.PROFILE]);
            return false;
        }
    }

    logout() {
        this.clearAuthentication();
        // try {
        // this.rootStore.apiStore.call(this.api, this.api.logout);
        //     this.clearAuthentication();
        // } catch (error) { }
    }

    *getTokenGG(authToken: string) {
        try {
            const params = new URLSearchParams();
            params.append('client_id', process.env.REACT_APP_CLIENT_ID!);
            params.append('client_secret', process.env.REACT_APP_CLIENT_SECRET!);
            params.append('code', authToken);
            params.append('grant_type', 'authorization_code');
            params.append('redirect_uri', process.env.REACT_APP_REDIRECT_URI!);
            const config = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };
            const res: ResponseData<ResponseDataExchangeIdTokenGG> =
                yield axios.post('https://oauth2.googleapis.com/token', params, config);
            if (res && res.data) {
                return res.data;
            }
        } catch (error) {
            return false;
        }
    }

    *exchangeAuthTokenGG(authToken: string) {
        try {
            const res: ResponseDataExchangeIdTokenGG = yield this.getTokenGG(authToken);
            if (res && res?.refresh_token) {
                this.access_token = res.access_token!;
                this.refresh_token = res.refresh_token!;
                const { id_token } = res;
                const userInfo = jwtDecode(id_token) as JwtPayload;
                if (userInfo) {
                    const { email, name, picture } = userInfo;
                    const resSV: ResponseData<LoginPostResponse> = yield this.rootStore.apiStore.call(
                        this.api,
                        this.api.googleLogin,
                        { email, fullName: name, avatar: picture, accessToken: this.access_token, refreshToken: this.refresh_token }
                    );
                    if (resSV?.ok) {
                        this.token = resSV.data.token;
                        this.avatar = resSV.data.user.avatar || null;
                        this.profile = {
                            id: resSV.data.user.id,
                            name: name,
                            email: resSV.data.user.adminEmail,
                            // avatar: resSV.data.user.avatar,
                            role_id: resSV.data.user.role_id
                        };
                        saveLocalStorage(keyStorage.TOKEN, resSV.data.token);
                        saveLocalStorage(keyStorage.PROFILE, JSON.stringify(this.profile!));
                        toastify('success', t('messages.sign_in_success'));
                        return resSV.data.user.role_id;
                    }
                    this.rootStore.modalStore.showErrorModal({
                        content: t('messages.sign_in_failed')
                    });
                    return false;
                }
                return false;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    *getMyInfor() {
        try {
            const res: ResponseData = yield this.rootStore.apiStore.call(this.api, this.api.getMyInfor, null, { hideLoading: true, disableAlertError: true });
            if (res?.ok) {
                return true;
            }
            return false;
        } catch (error) {
            return false;
        }
    }

    clearAuthentication(messageError?: any) {
        this.rootStore.modalStore.hideAllModals();
        removeStorage([keyStorage.TOKEN, keyStorage.AVATAR, keyStorage.PROFILE]);
        const navigate = getNavigate();
        try {
            var auth2 = gapi.auth2.getAuthInstance();
            if (auth2 && auth2.isSignedIn.get()) {
                auth2.signOut().then(function () {
                    if (navigate) {
                        navigate(ROUTES.login.href, { replace: true });
                    }
                    auth2.disconnect();
                });
            }
        } catch (error) {
            if (navigate) {
                navigate(ROUTES.login.href, { replace: true });
            }
        }
        if (navigate) {
            navigate(ROUTES.login.href, { replace: true });
        }
        if (messageError) {
            this.rootStore.modalStore.showErrorModal({
                content: messageError
            });
        }
    }
}
