import { ScrollArea } from '@/components/common/ScrollArea';
import Tooltip from '@/components/common/Tooltip';
import { getMenuList } from '@/utils/routes';
import { cn } from '@/utils/utils';
import { EllipsisIcon, LogOutIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { WrapperSheetClose } from '../SheetMenu';
import { Button } from '@/components/common/Button';
import CollapseMenuButton from './CollapseMenuButton';
import { useStore } from '@/hooks/useStore';
import { keyStorage, ROUTES } from '@/configs/constants';

interface MenuProps {
    isOpen?: boolean,
    useSheetClose?: boolean
}

export default function Menu({ isOpen, useSheetClose }: MenuProps) {
    // hooks
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const menuList = getMenuList(pathname);

    // store
    const {
        authStore: { logout, profile },
        clearAllStore
    } = useStore();

    // function
    const handleRedirect = (active: boolean, href: string) => {
        const token = localStorage.getItem(keyStorage.TOKEN);
        let profile = localStorage.getItem(keyStorage.PROFILE);
        !active && clearAllStore();
        if (!token || !profile) {
            window.location.href = ROUTES.login.href;
            return;
        }
        window.location.href = href;
    };

    return (
        <nav className='h-full w-full flex flex-col justify-between bg-white'>
            <ScrollArea className='[&>div>div[style]]:!block'>
                <ul className='flex flex-col max-h-[calc(100vh-48px-36px-16px-32px-30px)] items-center space-y-1'>
                    {menuList.map(({ groupLabel, menus }, index) => (
                        <li className={cn('w-full px-4 py-2', groupLabel ? 'pt-5' : '')} key={index}>

                            {/* {(isOpen && groupLabel) || isOpen === undefined ?
                                (
                                    <p className='text-sm font-medium text-muted-foreground px-4 pb-[7px] max-w-[248px] truncate'>
                                        {t(groupLabel)}
                                    </p>
                                ) :
                                isOpen === false && groupLabel ?
                                    (
                                        <Tooltip
                                            content={<p>{t(groupLabel)}</p>}
                                            triggerClassName='w-full'
                                            side='right'
                                        >
                                            <div className='w-full flex justify-center items-center'>
                                                <EllipsisIcon className='h-5 w-5' />
                                            </div>
                                        </Tooltip>
                                    ) :
                                    (
                                        <p className='pb-2'></p>
                                    )} */}
                            {menus.map(
                                ({ href, label, icon: Icon, active, submenus, image }, index) =>
                                    submenus.length === 0 ?
                                        (
                                            <div className={`w-full flex justify-start pt-2 ${isOpen === false ? 'bg-white' : ''}`} key={index}>
                                                <Tooltip
                                                    content={isOpen === false && t(label)}
                                                    triggerAsChild={true}
                                                    side='right'
                                                >
                                                    <WrapperSheetClose className='w-full' useSheetClose={useSheetClose}>
                                                        <Button
                                                            variant={active ? 'active' : 'notActive'}
                                                            className='w-full justify-start h-10 rounded-lg px-[12px] py-[10px] cursor-pointer'
                                                            onClick={() => handleRedirect(active, href)}
                                                            asChild
                                                        >
                                                            <div>
                                                                <span
                                                                    className={cn(isOpen === false ? 'pl-1' : 'pl-1 pr-[12px]')}
                                                                >
                                                                    {image ? <img src={image} className={`w-[24px] h-[24px] ${active ? 'filter invert contrast-125 hue-rotate-180' : ''} ${!isOpen && ' mr-[24px] '}`} /> : <Icon size={24} />}

                                                                </span>
                                                                <p
                                                                    className={cn(
                                                                        'max-w-[200px] truncate mt-[4px]',
                                                                        isOpen === false ?
                                                                            '-translate-x-100 opacity-0' :
                                                                            'translate-x-0 opacity-100'
                                                                    )}
                                                                >
                                                                    {t(label)}
                                                                </p>
                                                            </div>
                                                        </Button>
                                                    </WrapperSheetClose>
                                                </Tooltip>
                                            </div>
                                        ) :
                                        (
                                            <div className='w-full' key={index}>
                                                <CollapseMenuButton
                                                    icon={Icon}
                                                    label={label}
                                                    active={active}
                                                    submenus={submenus}
                                                    isOpen={isOpen}
                                                    useSheetClose={useSheetClose}
                                                />
                                            </div>
                                        )
                            )}
                        </li>
                    ))}
                </ul>
            </ScrollArea>

            {/* logout button */}
            <ul>
                <li className='w-full grow flex items-end justify-center mb-4'>
                    <Tooltip
                        content={isOpen === false && t('words_title.sign_out')}
                        triggerAsChild={true}
                        side='right'
                    >
                        <Button
                            onClick={logout}
                            variant='outline'
                            className='w-[80%] justify-start h-10 text-red400'
                        >
                            <span
                                className={cn(
                                    'flex items-center transition-all overflow-hidden',
                                    isOpen === true ? 'mx-auto' : ' absolute left-[40%]'
                                )}
                            >
                                <span>
                                    <LogOutIcon size={18} className='' />
                                </span>
                                <p
                                    className={cn(
                                        'whitespace-nowrap ml-4 transition-opacity',
                                        isOpen === false ? 'opacity-0 delay-150' : 'opacity-100'
                                    )}
                                >
                                    {t('words_title.sign_out')}
                                </p>
                            </span>
                        </Button>
                    </Tooltip>
                </li>
            </ul>
        </nav>
    );
}
