import { keyStorage, ROUTES } from '@/configs/constants';
import { GroupMenu, Route } from '@/types/routes';
import {
    ReceiptText,
    Users2Icon,
    Landmark
} from 'lucide-react';
import { getStorage } from './browsers';
import { roleIdEnum } from '@/types/enums';
import { Profile } from '@/types/auth';
import fileIcon from '../assets/images/folder-up.png';
import companyIcon from '../assets/images/company.png';

// #region Title Page
export const getTitlePage = (pathname: string): string => {
    if (!pathname) {
        return '';
    }
    const findRoute = Object.values(ROUTES).find(
        value => value.href === pathname
    );
    return findRoute?.title ?? '';
};
// #rendregion

// #region Breadcrumb
const STATIC_ROUTES_BREADCRUMB: {
    [key: string]: Route[]
} = {
    [ROUTES.companyManager.href]: [ROUTES.companyManager],
    [ROUTES.button.href]: [ROUTES.button],
    [ROUTES.calendar.href]: [ROUTES.calendar],
    [ROUTES.pagination.href]: [ROUTES.pagination],
    [ROUTES.table.href]: [ROUTES.table]
};

export const getBreadcrumb = (pathname: string): Route[] => {
    if (!pathname) return [];
    switch (pathname) {
        case STATIC_ROUTES_BREADCRUMB.hasOwnProperty(pathname) && pathname:
            return STATIC_ROUTES_BREADCRUMB[pathname];

        default:
            return [];
    }
};
// #endregion

// #region Menu
export const getMenuList = (pathname: string): GroupMenu[] => {
    const profile = getStorage(keyStorage.PROFILE, true);
    if (!profile) {
        return [];
    }

    const { role_id } = profile;
    const menuAdminSystem = [
        {
            href: ROUTES.companyManager.href,
            label: ROUTES.companyManager.menuLabel,
            active: pathname === ROUTES.companyManager.href,
            icon: Landmark,
            image: companyIcon,
            submenus: []
        }
    ];
    const menuAdminCompany = [
        {
            href: ROUTES.userManagement.href,
            label: ROUTES.userManagement.menuLabel,
            active: pathname === ROUTES.userManagement.href,
            icon: Users2Icon,
            submenus: []
        },
        {
            href: ROUTES.fileManager.href,
            label: ROUTES.fileManager.menuLabel,
            active: pathname === ROUTES.fileManager.href,
            icon: ReceiptText,
            image: fileIcon,
            submenus: []
        }
    ];
    return [
        {
            groupLabel: '',
            menus:
                role_id === roleIdEnum.adminSystem ? [...menuAdminSystem] : [...menuAdminCompany]

        }
    ];
};
// #endregion
