import { ChevronLeft } from 'lucide-react';
import { Button } from '@/components/common/Button';
import { observer } from 'mobx-react-lite';
import { useStore } from '@/hooks/useStore';
import { cn } from '@/utils/utils';
import menuIcon from '../../../../assets/images/menu-btn.png';

export default observer(function SidebarToggle() {
    // store
    const {
        uiStore: { isOpenSidebar, setOpenSidebar }
    } = useStore();

    return (
        <div className={`invisible lg:visible absolute top-[12px] z-20 ${isOpenSidebar ? 'right-[16px]' : 'right-[24px]'}`}>
            <Button
                onClick={() => setOpenSidebar()}
                className={cn(' w-[44px] h-[44px] transition-transform ease-in-out duration-700 bg-transparent hover:bg-transparent', isOpenSidebar === false ? 'rotate-180' : 'rotate-0')}
                variant='default'
                size='icon'
            >
                <img src={menuIcon} className='w-full h-full' />
            </Button>
        </div>
    );
});
