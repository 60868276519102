import { observer } from 'mobx-react-lite';
import {
    useEffect,
    useState
} from 'react';
import { gapi } from 'gapi-script';
import { bytesToMB, generateGUID } from '@/utils/utils';
import { File, BodyPostNewFile, CheckFolerMonth } from '@/types/files';
import { useStore } from '@/hooks/useStore';
import { fileTypeFormat, uploadStatus } from '@/types/enums';
import Dropzone from 'react-dropzone';
import { Button } from '@/components/common/Button';
import { ColumnDef } from '@tanstack/react-table';
import Table from '@/components/table/Table';
import { Trash2, RefreshCcw, FileX } from 'lucide-react';
import { ACTIVE, NOT_DELETE } from '@/configs/constants';
import { getIdFolderDriveFromURL } from '@/utils/file';
import { flowResult } from 'mobx';
import doneIcon from '../../assets/images/done.png';
import failIcon from '../../assets/images/alert.png';
import loadingIcon from '../../assets/images/loading.png';
import folderUp from '../../assets/images/folder-up.png';
import GoogleAPI from '@/services/drive';
import { t } from 'i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export default observer(function FilesManagement() {
    const {
        authStore: { profile, getMyInfor },
        fileStore: { getCustomerFolderId, postNewFile },
        modalStore: { showErrorModal }
    } = useStore();
    const [listUploadTemp, setListUploadTemp] = useState<File[]>([]);
    const [listFolderMonth, setListFolderMonth] = useState<CheckFolerMonth[]>([]);
    const [isUpoading, setIsUploading] = useState<boolean>(false);

    let ROOT_FOLDER_ID = getIdFolderDriveFromURL(profile?.shared_folder_url_id);
    const googleAPIService = GoogleAPI.getInstance();
    useEffect(() => {
        const initGoogleAPI = async () => {
            await googleAPIService.initClient();
        };
        initGoogleAPI();
    }, []);

    useEffect(() => {
        if (listUploadTemp.filter(e => e.uploaded === uploadStatus.WAITING_REUP).length > 0 &&
            !listUploadTemp.find(e => e.uploaded === uploadStatus.UPLOADING)) {
            handleClickUpload(true);
        }
        if (listUploadTemp.some(e => e.uploaded === (uploadStatus.UPLOADING || uploadStatus.WAITING_REUP))) {
            setIsUploading(false);
        }
    }, [listUploadTemp]);

    const handleUploadFile = async (file, delay = false) => {
        if (!ROOT_FOLDER_ID) {
            showErrorModal({
                content: t('messages.share_folder_invalid')
            });
            return false;
        }
        const permission = await googleAPIService.checkPermissionUploadById(ROOT_FOLDER_ID, profile?.email);
        if (!permission) {
            showErrorModal({
                content: t('messages.permission_folder_fail')
            });
            return false;
        }
        const name = file.path;
        const dataSplit = await googleAPIService.splitNameFile(name);
        if (!dataSplit) {
            return false;
        } else {
            const current: CheckFolerMonth = {
                user: dataSplit.userFolder,
                month: dataSplit.monthFolder
            };
            if (listFolderMonth.find(e => e.user === dataSplit.userFolder && e.month === dataSplit.monthFolder)) {
                return undefined;
            } else {
                listFolderMonth.push(current);
            }
            let customerDetail = await flowResult(getCustomerFolderId(dataSplit.userFolder));
            if (!customerDetail || customerDetail.company_id !== profile?.company_id || customerDetail.status !== ACTIVE || customerDetail.delete_flag !== NOT_DELETE) {
                return false;
            }
            let folderIdCus = customerDetail?.folder_url_id!;
            const checkCusFolder = await googleAPIService.checkFolderExist(customerDetail.folder_url_id!, ROOT_FOLDER_ID);
            if (!checkCusFolder) {
                const res = await googleAPIService.createFolderInParent(dataSplit.userFolder, ROOT_FOLDER_ID);
                if (res) {
                    folderIdCus = res;
                }
            }
            const monthFolderID = await googleAPIService.findFolderByNameAndParentId(folderIdCus, dataSplit.monthFolder);
            if (!monthFolderID) {
                setTimeout(() => {
                    setListUploadTemp(prevItems =>
                        prevItems.map(item =>
                            item.tempId === file.tempId ?
                                { ...item, uploaded: uploadStatus.FAIL } :
                                item));
                }, delay ? 1000 : 0);

                return;
            }
            const metadata = {
                name: file.path,
                mimeType: file.mimeType,
                parents: [monthFolderID]
            };
            const form = new FormData();
            form.append(
                'metadata',
                new Blob([JSON.stringify(metadata)], { type: 'application/json' })
            );
            form.append('file', file.originFile);
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open(
                    'POST',
                    'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart'
                );
                xhr.setRequestHeader(
                    'Authorization',
                    `Bearer ${gapi.auth.getToken().access_token}`
                );
                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = (event.loaded / event.total) * 100;
                        setListUploadTemp(prevItems =>
                            prevItems.map(item =>
                                item.tempId === file.tempId ?
                                    { ...item, progress: percentComplete } :
                                    item));
                    }
                };
                xhr.onload = async () => {
                    if (xhr.status >= 200 && xhr.status < 300) {
                        const response = JSON.parse(xhr.responseText);
                        const body: BodyPostNewFile = {
                            name: file.path,
                            customer_id: parseInt(`${customerDetail?.id}`),
                            company_id: parseInt(`${customerDetail?.company_id}`),
                            path: response.id,
                            upload_month: dataSplit.monthFolder.slice(4),
                            upload_year: dataSplit.monthFolder.slice(0, 4)
                        };
                        await flowResult(postNewFile(body));
                        resolve(response.id);
                    } else {
                        reject(false);
                    }
                };
                xhr.onerror = () => {
                    reject(false);
                };
                xhr.send(form);
            });
        }
    };

    const onPreUpload = (files) => {
        if (!ROOT_FOLDER_ID) {
            showErrorModal({
                content: t('messages.share_folder_invalid')
            });
            return;
        }
        const filesToUpload2 = Array.from(files);
        filesToUpload2.forEach(async (f: any) => {
            const typeFile = f.type;
            const filename = f.name;
            const nameValid = await googleAPIService.checkFileNameInput(filename);
            if (typeFile !== '') {
                const tempId = generateGUID();
                const sizeInMB: number = bytesToMB(f.size);
                let temp = {
                    ...f,
                    mimeType: f.type,
                    path: f.name,
                    name: f.name,
                    id: '',
                    tempId: tempId,
                    originFile: f,
                    uploaded:
          sizeInMB >
          Number.parseFloat(
              process.env.REACT_APP_MAX_SIZE_FILE_IN_MB || '50'
          ) || typeFile !== fileTypeFormat.PDF || !nameValid ?
              uploadStatus.ERROR_FILE :
              uploadStatus.WAITING
                };
                setListUploadTemp(prev => [...prev, temp]);
                if (sizeInMB >
                    Number.parseFloat(
                        process.env.REACT_APP_MAX_SIZE_FILE_IN_MB || '50'
                    ) || typeFile !== fileTypeFormat.PDF || !nameValid) {
                    showErrorModal({
                        id: 'file_error',
                        content: t('messages.file_error')
                    });
                }
            }
        });
    };

    const getColorTextTableFiles = (statusFile: string | undefined) => {
        switch (statusFile) {
            case uploadStatus.WAITING:
                return 'text-black';
            case uploadStatus.DONE:
                return 'text-green-500';
            case uploadStatus.ERROR_FILE:
                return 'text-yellow-600';
            case uploadStatus.FAIL:
                return 'text-red400';
            case uploadStatus.UPLOADING:
                return 'text-blue-500';
            case uploadStatus.WAITING_REUP:
                return 'text-blue-500';
            default:
                return 'text-black';
        }
    };

    const onDeleteFiles = (original: File) => {
        setListUploadTemp(prevItems =>
            prevItems.filter(item => item.tempId !== original.tempId));
    };

    const onRetry = async (e: File) => {
        setListFolderMonth([]);
        setListUploadTemp(prevItems =>
            prevItems.map(item =>
                item.tempId === e.tempId ?
                    { ...item, uploaded: uploadStatus.UPLOADING, progress: 0 } :
                    item));
        const res = await handleUploadFile(e, true);
        if (res) {
            setListUploadTemp(prevItems =>
                prevItems.map(item =>
                    item.tempId === e.tempId ?
                        { ...item, uploaded: uploadStatus.DONE, id: `${res}` } :
                        item));
        } else {
            setTimeout(() => {
                setListUploadTemp(prevItems =>
                    prevItems.map(item =>
                        item.tempId === e.tempId ?
                            { ...item, uploaded: uploadStatus.FAIL } :
                            item));
            }, 1000);
        }
    };

    const columns: ColumnDef<File>[] = [
        {
            accessorKey: 'name',
            header: 'File name',
            size: 200,
            enableSorting: false,
            cell: ({ row: { original, index } }) => (
                <div className='max-w-[800px] block'>
                    <div className='w-full flex'>
                        <p className='mx-[8px]'>
                            {index + 1}
                            .
                        </p>
                        <a
                            href={original.id ? `https://drive.google.com/file/d/${original.id}/view` : undefined}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={`${getColorTextTableFiles(original.uploaded)} text-[15px] ${original.uploaded === uploadStatus.DONE && 'underline cursor-pointer'}`}
                        >
                            {original.name}
                        </a>
                    </div>
                    {((original.progress > 0 && original.progress < 100) ||
                        (original.progress === 100 && original.uploaded === uploadStatus.UPLOADING)) &&
                        (
                            <div className='w-full mt-[2px]'>
                                <progress className='w-full' max={100} value={original.progress} />
                            </div>
                        )}
                </div>

            )
        },
        {
            header: 'Status',
            size: 100,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div className='flex justify-start max-w-[150px] w-[150px]'>
                    {(original.uploaded === uploadStatus.UPLOADING || original.uploaded === uploadStatus.WAITING_REUP) ?
                        (
                            <div className='flex justify-end items-center'>
                                <img src={loadingIcon} className='animate-[spin_1s_linear_infinite] w-[16px] h-[16px]' />
                                <p className={`text-[15px] pl-[8px] ${getColorTextTableFiles(original.uploaded)}`}>アップロード中</p>
                            </div>
                        ) :
                        original.uploaded === uploadStatus.DONE ?
                            (
                                <div className='flex justify-end items-center'>
                                    <img src={doneIcon} className='w-[16px] h-[16px]' />
                                    <p className={`text-[15px] pl-[8px] ${getColorTextTableFiles(original.uploaded)}`}>アップロード済み</p>
                                </div>
                            ) :
                            original.uploaded === uploadStatus.ERROR_FILE ?
                                (
                                    <div className='flex justify-end items-center text-yellow-500'>
                                        <FileX className='text-yellow-600 w-[20px] h-[20px]' />
                                        <p className={`text-[15px] pl-[8px] ${getColorTextTableFiles(original.uploaded)}`}>無効なファイル</p>
                                    </div>
                                ) :
                                original.uploaded === uploadStatus.FAIL ?
                                    (
                                        <div className='flex justify-end items-center text-yellow-500'>
                                            <img src={failIcon} className='w-[16px] h-[16px]' />
                                            <p className={`text-[15px] pl-[8px] ${getColorTextTableFiles(original.uploaded)}`}>失敗</p>
                                        </div>
                                    ) :
                                    null}
                </div>
            )
        },
        {
            header: 'Actions',
            size: 10,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div
                    className='flex justify-end w-full'
                >
                    {/* <div className='flex '> */}
                    <button
                        disabled={original.uploaded !== (uploadStatus.FAIL) ||
                            (listUploadTemp.some(e => e.uploaded === (uploadStatus.UPLOADING || uploadStatus.WAITING_REUP)))}
                        onClick={() => onRetry(original)}
                        className='text-blackForce cursor-pointer disabled:text-transparent disabled:cursor-default'
                        data-tooltip-id='tootip-re-upload'
                        data-tooltip-hidden={listUploadTemp.some(e => e.uploaded === (uploadStatus.UPLOADING || uploadStatus.WAITING_REUP))}
                        data-tooltip-delay-show={240}
                    >
                        <RefreshCcw size={20} />
                    </button>
                    <button
                        disabled={original.uploaded !== uploadStatus.FAIL && original.uploaded !== uploadStatus.WAITING && original.uploaded !== uploadStatus.ERROR_FILE}
                        onClick={() => onDeleteFiles(original)}
                        className='text-blackForce mx-[8px] cursor-pointer disabled:text-transparent disabled:cursor-default'
                        data-tooltip-id='tootip-delete'
                        data-tooltip-hidden={listUploadTemp.some(e => e.uploaded === (uploadStatus.UPLOADING || uploadStatus.WAITING_REUP))}
                        data-tooltip-delay-show={240}
                    >
                        <Trash2 size={20} />
                    </button>

                    {/* </div> */}
                </div>
            )
        }
    ];

    const handleClickUpload = async (ignoreFail = false) => {
        setIsUploading(true);
        const res = await flowResult(getMyInfor());
        if (res) {
            setListFolderMonth([]);
            if (profile?.shared_folder_url_id?.lastIndexOf('https://drive.google.com/drive') !== 0) {
                showErrorModal({
                    content: t('messages.share_folder_invalid')
                });
                return;
            }
            const permission = await googleAPIService.checkPermissionUploadById(ROOT_FOLDER_ID, profile?.email);
            if (!permission) {
                showErrorModal({
                    content: t('messages.permission_folder_fail')
                });
                return;
            }
            const listForUpload = listUploadTemp.filter(e => ignoreFail ? (e.uploaded === uploadStatus.WAITING_REUP) : (e.uploaded === (uploadStatus.WAITING || uploadStatus.FAIL)));
            if (listForUpload && listForUpload.length > 0) {
                setListUploadTemp(prevItems =>
                    prevItems.map(item =>
                        (ignoreFail ?
                            (item.uploaded === uploadStatus.WAITING_REUP) :
                            (item.uploaded === (uploadStatus.WAITING || uploadStatus.FAIL))) ?
                            { ...item, uploaded: uploadStatus.UPLOADING } :
                            item));
                await Promise.all(
                    listForUpload.map(async (e: File) => {
                        const res = await handleUploadFile(e);
                        if (res && typeof res === 'string') {
                            setListUploadTemp(prevItems =>
                                prevItems.map(item =>
                                    item.tempId === e.tempId ?
                                        { ...item, uploaded: uploadStatus.DONE, id: `${res}` } :
                                        item));
                        } else if (res === undefined) {
                            setListUploadTemp(prevItems =>
                                prevItems.map(item =>
                                    item.tempId === e.tempId ?
                                        { ...item, uploaded: uploadStatus.WAITING_REUP } :
                                        item));
                        } else {
                            setListUploadTemp(prevItems =>
                                prevItems.map(item =>
                                    item.tempId === e.tempId ?
                                        { ...item, uploaded: uploadStatus.FAIL } :
                                        item));
                        }
                    })
                );
            }
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) return;
        onPreUpload(files);
    };

    const handleFileInputReset = () => {
        const fileInput = document.getElementById('upload') as HTMLInputElement;
        fileInput.value = '';
        const fileInputFolder = document.getElementById('uploadFolder') as HTMLInputElement;
        fileInputFolder.value = '';
    };

    return (
        <div className=''>
            <Dropzone onDrop={acceptedFiles => onPreUpload(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className='text-center text-gray-500'>
                        <div className='w-full bg-white border-dashed border-[1px] border-black rounded-xl'>
                            <div className=' flex justify-center text-center text-[15px] font-semibold py-[10px] text-black'>
                                {t('messages.des_upload')}
                            </div>
                            <div className='flex justify-center pb-[10px]'>
                                <div className=''>
                                    <input
                                        disabled={listUploadTemp.filter(e => e.uploaded === uploadStatus.UPLOADING).length > 0}
                                        type='file'
                                        multiple
                                        style={{ display: 'none' }}
                                        id='upload'
                                        accept='.pdf'
                                        onClick={handleFileInputReset}
                                        onChange={handleFileChange}
                                    />
                                    <Button
                                        className='  text-white rounded-sm text-[16px] justify-center content-center cursor-pointer'
                                        variant='second'
                                    >
                                        <label
                                            htmlFor='upload'
                                            className=' w-full h-full text-white rounded-sm text-[13px] justify-center content-center cursor-pointer'
                                        >
                                            {t('messages.select_file')}
                                        </label>
                                    </Button>

                                </div>
                                <div className=' ml-[16px]'>
                                    <input
                                        disabled={listUploadTemp.filter(e => e.uploaded === uploadStatus.UPLOADING).length > 0}
                                        type='file'
                                        webkitdirectory='true'
                                        style={{ display: 'none' }}
                                        id='uploadFolder'
                                        onClick={handleFileInputReset}
                                        onChange={handleFileChange}
                                    />
                                    <Button variant='second' className=''>
                                        <label
                                            htmlFor='uploadFolder'
                                            className=' w-full h-full text-white rounded-sm text-[13px] justify-center content-center cursor-pointer'
                                        >
                                            {t('messages.select_folder')}
                                        </label>
                                    </Button>

                                </div>
                            </div>
                            <div className='text-[13px] mb-[12px]'>{t('messages.file_limit')}</div>
                        </div>
                    </div>
                )}
            </Dropzone>
            <div className='w-full flex justify-between pt-4 '>
                <div className='w-[70%] max-h-[calc(100vh-68px-145px-16px)] overflow-y-scroll'>
                    {listUploadTemp && (
                        <Table
                            columns={columns}
                            data={listUploadTemp}
                            showSelectPageSize={false}
                            getRowId={row => row.tempId?.toString()}
                            headerClassName='hidden'
                            showPagination={false}
                            showPagingInfo={false}
                            noData=''
                            cellClassName='truncate'
                            fixOverText
                        />
                    )}
                </div>
                <Button
                    type='submit'
                    variant='primary'
                    onClick={() => handleClickUpload()}
                    disabled={!listUploadTemp ||
                        listUploadTemp.length <= 0 ||
                        !listUploadTemp.some(e => (e.uploaded === uploadStatus.WAITING)) ||
                        listUploadTemp.some(e => (e.uploaded === uploadStatus.ERROR_FILE)) ||
                        listUploadTemp.some(e => (e.uploaded === uploadStatus.WAITING_REUP)) ||
                        listUploadTemp.some(e => (e.uploaded === uploadStatus.UPLOADING)) || isUpoading}
                    className='text-[13px]'
                >
                    <img src={folderUp} className='filter invert contrast-125 hue-rotate-180 mr-[12px]' />
                    {t('messages.upload')}
                </Button>
            </div>

            <ReactTooltip
                id='tootip-delete'
                place='top'
                content='削除'
            />
            <ReactTooltip
                id='tootip-re-upload'
                place='top'
                content='再アップロード'
            />
        </div>
    );
});
