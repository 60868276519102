import ApiService from './ApiService';

export default class FileApi extends ApiService {
    getFolderIDCustomer(customerId) {
        return this.get(`customers/checkExistCustomerId/${encodeURIComponent(customerId)}`);
    }

    postNewFileUploaded(payload) {
        return this.post('files', payload);
    }
}
