import { Button } from '@/components/common/Button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/common/Form';
import { Input } from '@/components/common/Input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/common/Select';
import { Switch } from '@/components/common/Switch';
import Table from '@/components/table/Table';
import { DATE_TIME, DEFAULT_SELECT_PAGE_SIZES, USER_STATUS } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import yup from '@/services/yup';
import { User } from '@/types/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { ColumnDef, TableState, Updater } from '@tanstack/react-table';
import { t } from 'i18next';
import { DownloadIcon, HardDriveDownload, SquarePen, Trash2 } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import UserForm from '../modal/UserForm';
import { flowResult, toJS } from 'mobx';
import { STATUS } from '@/types/enums';
import { toastify } from '@/utils/toastify';
import { format } from 'date-fns';
import addPerson from '../../../assets/images/add-person.png';
import GoogleAPI from '@/services/drive';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Profile } from '@/types/auth';

export default observer(function UserManagementPage() {
    // store
    const {
        userStore: { getListUsers, getUserDetail, users, paging, totalRecord, downloadORCode, updateStatusUSer, defaultPaging, keepSearchParams, setObservable },
        modalStore: { showModal, hideModal },
        apiStore: { setLoading },
        authStore: { profile, setProfile },
        companyStore: { getDetailCompany }
    } = useStore();
    const googleAPIService = GoogleAPI.getInstance();

    // validate schema
    const validateSchema = yup.object().shape({
        customerCode: yup
            .string(),
        status: yup.string()
    });

    type FormData = yup.InferType<typeof validateSchema>;

    const initialValues = {
        customerCode: '',
        status: 'all'
    };

    const form = useForm<FormData>({
        resolver: yupResolver(validateSchema),
        mode: 'onChange',
        defaultValues: initialValues
    });
    // state

    const [searchState, setSearchState] = useState<Object>(keepSearchParams ?? '');

    // lifecycle
    useEffect(() => {
        const initGoogleAPI = async () => {
            await googleAPIService.initClient();
            // getRootFolder();
        };
        initGoogleAPI();
        if (keepSearchParams) {
            form.reset(keepSearchParams);
            onFetchUser(keepSearchParams, paging);
        } else {
            onSearch(searchState);
        }
    }, []);

    // const getRootFolder = async () => {
    //     if (profile && profile.company_id) {
    //         const idCompany = profile?.company_id;
    //         if (idCompany) {
    //             const resMyInfo = await flowResult(getDetailCompany(`${idCompany}`));
    //             const { shared_folder_url_id } = resMyInfo;
    //             if (shared_folder_url_id) {
    //                 const newProfile: Profile = { ...profile, shared_folder_url_id: shared_folder_url_id };
    //                 setProfile(newProfile);
    //             }
    //         }
    //     } else {
    //         setTimeout(() => {
    //             getRootFolder();
    //         }, 240);
    //     }
    // };

    // function
    const onSearch = (searchParams: FormData) => {
        setSearchState(searchParams);
        onFetchUser(searchParams, defaultPaging);
    };

    const onFetchUser = (searchParams?: any, paging?: TableState) => {
        if (paging) {
            setObservable('paging', paging ?? null);
        }
        let params = { ...searchParams };
        if (params?.status && params?.status === 'all') {
            params.status = null;
        }

        setObservable('keepSearchParams', toJS(searchParams));
        getListUsers(params, paging);
        // setLoading(false);
    };

    const onStateChange = (stateUpdater: Updater<TableState>) => {
        const newPaging = stateUpdater instanceof Function ? stateUpdater(paging) : stateUpdater;
        onFetchUser(searchState, newPaging);
    };

    const onShowCreateUserForm = () => {
        showModal({
            id: 'create-user',
            title: '新規追加',
            size: '2xl',
            content: <UserForm onFetch={() => onFetchUser(keepSearchParams, paging)} />
        });
    };

    const onShowUpdateUserForm = (id: number, currentCusId: string) => {
        showModal({
            id: 'update-user',
            title: '情報編集',
            size: '2xl',
            content: <UserForm id={id} currentCusId={currentCusId} onFetch={() => onFetchUser(keepSearchParams, paging)} />
        });
    };

    const handleCallBackDelete = (paging: TableState, currentListLength: number) => {
        let currentPage = { ...paging };
        if (currentListLength < 2 && currentPage.pagination.pageIndex > 0) {
            currentPage.pagination.pageIndex = currentPage.pagination.pageIndex - 1;
        };
        onFetchUser(keepSearchParams, currentPage);
    };
    const onDeleteUser = (cusId: string, id: number) => {
        showModal({
            id: 'confirm-delete',
            title: '確認',
            content: (
                <div className='w-full text-center'>
                    <p className='mt-6 text-[14px] text-black'>{t('messages.confirm_delete')}</p>
                    <div className='flex flex-row text-center mt-8 w-full justify-end'>
                        <Button onClick={() => hideModal()} className='min-w-32 bg-white text-red400' variant='outline'>
                            いいえ
                        </Button>
                        <Button
                            variant='outline'
                            onClick={async () => {
                                hideModal('confirm-delete');
                                // setLoading(true);
                                const res = await googleAPIService.deleteFolder(cusId, id);
                                if (res) {
                                    toastify('success', t('messages.delete_success'));
                                    handleCallBackDelete(paging, users.length);
                                    // onFetchUser(keepSearchParams, paging);
                                }
                            }}
                            className='min-w-32  ml-[20px] bg-red400 text-white'
                        >
                            はい
                        </Button>
                    </div>
                </div>
            )
        });
    };

    const onDownloadQRCode = async (id: number) => {
        try {
            // Gọi API để lấy file PDF
            const res = await flowResult(downloadORCode({ id }));
            if (res) {
                const link = document.createElement('a');
                link.href = `data:application/pdf;base64,${res.pdf}`;
                link.download = res.fileName; // Tên file mà bạn muốn
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error('No PDF URL received');
            }
        } catch (error) {
            console.error('Failed to download QR code PDF:', error);
        }
    };

    const onChangeStatus = async (id: number | undefined, checked: boolean) => {
        showModal({
            id: 'confirm-changeStatus',
            title: '確認',
            content:
            (
                <div className='w-full text-center'>
                    <p className='mt-6 text-[14px] text-black'>{checked ? t('messages.confirm_active') : t('messages.confirm_inactive')}</p>
                    <div className='flex flex-row text-center mt-8 w-full justify-end'>
                        <Button variant='outline' onClick={() => hideModal()} className='min-w-32 bg-white text-red400'>
                            いいえ
                        </Button>
                        <Button
                            variant='outline'
                            onClick={async () => {
                                const res = await flowResult(updateStatusUSer({ id, isActive: checked ? STATUS.Active.toString() : STATUS.Inactive.toString() }));
                                if (res) {
                                    hideModal('confirm-changeStatus');
                                    toastify('success', t('messages.update_success'));
                                    onFetchUser(keepSearchParams, paging);
                                }
                            }}
                            className='min-w-32  ml-[20px] bg-red400 text-white'
                        >
                            はい
                        </Button>
                    </div>
                </div>
            )
        });
    };

    // columns
    const columns: ColumnDef<User>[] = [
        {
            header: 'No',
            size: 50,
            enableSorting: false,
            cell: ({ row: { index } }) => (
                <div>{paging.pagination.pageIndex * paging.pagination.pageSize + index + 1}</div>
            )
        },
        {
            accessorKey: 'customer_id',
            header: '顧客ID',
            size: 400,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div>{original.customer_id}</div>
            )
        },
        {
            accessorKey: 'lineId',
            header: '利用者 LINE ID',
            size: 400,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div>{original.line_id}</div>
            )
        },
        {
            accessorKey: 'createdAt',
            header: '登録日時',
            size: 300,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div>{format(original.createdAt!, DATE_TIME)}</div>
            )
        },
        {
            accessorKey: 'status',
            header: 'ステータス',
            size: 200,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div>
                    <Switch
                        data-tooltip-id={original.status === STATUS.Active ? 'tootip-active' : 'tootip-inactive'}
                        checked={original?.status === STATUS.Active}
                        onCheckedChange={(checked) => {
                            onChangeStatus(original.id, checked);
                        }}
                    >
                    </Switch>
                </div>
            )
        },
        {
            id: 'action',
            header: 'アクション',
            size: 200,
            enableSorting: false,
            cell: ({ row: { original } }) => (
                <div className='flex justify-start items-center gap-2'>
                    <button data-tooltip-id='tootip-download' className=' p-2 bg-bgUnCheck rounded-full' onClick={() => onDownloadQRCode(original.id)}>
                        <HardDriveDownload size={16} className='text-icon' />
                    </button>
                    {/* <button data-tooltip-id='tootip-edit'  className=' p-2 bg-bgUnCheck rounded-full' type='button' onClick={() => onShowUpdateUserForm(original.id, original.customer_id)}>
                        <SquarePen size={16} className='text-icon' />
                    </button> */}
                    <button
                        data-tooltip-id='tootip-delete'
                        className=' p-2 bg-bgUnCheck rounded-full'
                        type='button'
                        onClick={() => {
                            onDeleteUser(original.customer_id, original.id);
                        }}
                    >
                        <Trash2 size={16} className='text-icon' />
                    </button>
                </div>
            )
        }
    ];

    return (
        <div className=''>
            <div className='bg-white p-6 rounded-lg'>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSearch)}>
                        <div className='flex justify-between items-start w-full'>
                            <div className='w-[40%]'>
                                <FormField
                                    control={form.control}
                                    name='customerCode'
                                    render={({ field }) => (
                                        <FormItem>
                                            <div className='flex gap-2 items-center'>
                                                <FormControl>
                                                    <Input className='' placeholder='顧客ID' type='text' {...field} />
                                                </FormControl>
                                            </div>
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className='w-[40%]'>
                                <FormField
                                    control={form.control}
                                    name='status'
                                    render={({ field }) => (
                                        <FormItem>
                                            <div className='flex gap-2 items-center'>
                                                <Select
                                                    key={field.value}
                                                    onValueChange={field.onChange}
                                                    value={field.value}
                                                >
                                                    <FormControl>
                                                        <SelectTrigger className='w-full h-[40px] bg-bgInput border-none'>
                                                            <SelectValue placeholder='ステータス' />
                                                        </SelectTrigger>
                                                    </FormControl>
                                                    <SelectContent>
                                                        <SelectItem
                                                            className='hover:cursor-pointer'
                                                            value='all'
                                                        >
                                                            <span className='text-txtHolder'>ステータス</span>
                                                        </SelectItem>
                                                        {Object.keys(USER_STATUS).map((key, index) => (
                                                            <SelectItem
                                                                className='hover:cursor-pointer'
                                                                key={index}
                                                                value={USER_STATUS[key].value}
                                                            >
                                                                <span>{USER_STATUS[key].label}</span>
                                                            </SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <Button variant='second' type='submit' className='w-[150px]'>検索</Button>
                        </div>
                    </form>
                </Form>
            </div>
            <div className='bg-white p-6 rounded-lg mt-6 '>
                <Table
                    txtButtonRight='新規追加'
                    onClickRightButton={onShowCreateUserForm}
                    imgBtnRignt={addPerson}
                    columns={columns}
                    data={users}
                    state={paging}
                    rowCount={totalRecord}
                    onStateChange={onStateChange}
                    selectPageSizes={DEFAULT_SELECT_PAGE_SIZES}
                    getRowId={row => row.id?.toString()}
                    cellClassName='border-b-[1px] border-b-bgInput'
                />
            </div>
            <ReactTooltip
                id='tootip-active'
                place='top'
                content='無効'
            />
            <ReactTooltip
                id='tootip-inactive'
                place='top'
                content='有効'
            />
            <ReactTooltip
                id='tootip-delete'
                place='top'
                content='削除'
            />
            <ReactTooltip
                id='tootip-edit'
                place='top'
                content='編集'
            />
            <ReactTooltip
                id='tootip-download'
                place='top'
                content='ダウンロード'
            />
        </div>
    );
});
