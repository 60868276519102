// src/services/navigationService.ts
let navigate: ((path: string, options?: { replace?: boolean }) => void) | null = null;

export const setNavigate = (nav: (path: string, options?: { replace?: boolean }) => void) => {
    navigate = nav;
};

export const getNavigate = () => {
    if (!navigate) {
        console.warn('Navigate function has not been set.');
    }
    return navigate;
};
