import React from 'react';
import { useStore } from './useStore';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { roleIdEnum, RoleIdType } from '@/types/enums';
import AdminPanelLayout from '@/layouts/AdminPanelLayout';
import { NotFoundPage } from '@/pages';

// Định nghĩa kiểu cho các props của HOC
interface WithAuthenticationProps {
    // Các props khác bạn muốn truyền vào
    roles?: RoleIdType[]
}

export function withAuthentication<P extends object>(
    WrappedComponent: React.ComponentType<P>
) {
    const WithAuthentication = (props: P & WithAuthenticationProps): React.ReactElement => {
        // store
        const {
            authStore: { token, profile }
        } = useStore();

        if (!token) {
            return <Navigate to={ROUTES.login.href} />;
        }
        if (props?.roles && props.roles.length > 0) {
            if (profile && !props?.roles?.includes(profile.role_id)) {
                return (
                    <AdminPanelLayout>
                        <NotFoundPage />
                    </AdminPanelLayout>
                );
            }
        }

        return <WrappedComponent {...(props as P)} />;
    };

    return WithAuthentication;
}
