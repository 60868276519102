import { useTranslation } from 'react-i18next';

interface PagingInfoProps {
    pageIndex: number,
    pageSize: number,
    totalRecords: number,
    className?: string
}

export default function PagingInfo({ pageIndex, pageSize, totalRecords, className = '' }: PagingInfoProps) {
    // hooks
    const { t } = useTranslation();

    // variables
    let to = (pageIndex + 1) * pageSize;
    if (to > totalRecords) {
        to = totalRecords;
    }

    return (
        <span className={className}>
            {totalRecords}
            {' '}
            件中
            {pageIndex * pageSize + 1}
            ~
            {to}
            {' '}
            件を表示
        </span>
    );
}
