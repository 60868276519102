import ApiService from './ApiService';
export default class CompanyApi extends ApiService {
    createCompany(payload) {
        return this.post('companies', payload);
    }

    getCompanyDetailById(id) {
        return this.get(`companies/${id}`);
    }

    getListCompany(payload) {
        return this.get('companies', payload);
    }

    changeStatusActived({ id, payload }) {
        return this.put(`companies/status/${id}`, payload);
    }

    deteteCompanyById(id) {
        return this.delete(`companies/${id}`);
    }

    updateDetailCompany({ id, payload }) {
        return this.put(`companies/${id}`, payload);
    }
}
