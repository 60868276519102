import SelectLanguage from '@/components/general/SelectLanguage';
import BackgroundPanel from '@/components/layout/BackgroundPanel';
import { Outlet } from 'react-router-dom';

export default function FormPanelLayout() {
    return (
        <div className='w-screen h-screen lg:px-0 bg-cover bg-center bg-bg-login'>
            {/* <BackgroundPanel /> */}
            {/* <div className='absolute top-4 right-4 md:right-8 md:top-8'> */}
            {/* <SelectLanguage /> */}
            {/* </div> */}
            <Outlet />
        </div>
    );
}
